import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainSummaryDetails from './MainSummaryDetails';
import PackageSummaryCard from './PackageSummaryCard';

function MainSummaryStatistics({ data, partner }) {
	return (
		<Box display="flex">
			<PackageSummaryCard data={data} partner={partner} />
			<MainSummaryDetails data={data} />
		</Box>
	);
}

MainSummaryStatistics.propTypes = {
	data: PropTypes.shape({
		_id: PropTypes.string,
		numberOfParcels: PropTypes.number.isRequired,
		delivered: PropTypes.number.isRequired,
		failed: PropTypes.number.isRequired,
		cashOnDelivery: PropTypes.number.isRequired,
		failedClosed: PropTypes.number.isRequired,
		hasCourier: PropTypes.number.isRequired,
		fixed: PropTypes.number.isRequired,
		imported: PropTypes.number.isRequired,
		noDelivery: PropTypes.number.isRequired,
		fixedPricingModelCourierPrice: PropTypes.number.isRequired,
		dynamicPricingModelCourierPrice: PropTypes.number.isRequired,
		partnerPrice: PropTypes.number.isRequired,
	}).isRequired,
};

export default MainSummaryStatistics;
