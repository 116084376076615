import { useMutation, useNotify } from 'ra-core';

const useGetReportDownloadUrl = (onSuccessfulFecth) => {
	const notify = useNotify();
	const [mutate, { loading, data }] = useMutation();

	const handleGetReportDownloadUrl = (id) => {
		mutate(
			{
				type: 'getReportDownloadUrl',
				payload: { id },
			},
			{
				onSuccess: onSuccessfulFecth,
				onFailure: (error) => {
					notify(
						`Hiba: Nem sikerült betölteni a letöltéshez szükséges linket: ${error}`,
						'warning'
					);
				},
			}
		);
	};

	return { handleGetReportDownloadUrl, loading, data };
};

export default useGetReportDownloadUrl;
