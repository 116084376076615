import { useAuthState, useLogout } from 'react-admin';

/**
 * Accessing "/"" or "/dashboard" path without user redirects to login
 * returns userLoggedIn: true if checkAuth is finished and user is authenticated
 */
export function useLoadingRedirect() {
	const logout = useLogout();
	const { loaded, authenticated } = useAuthState();

	if (!authenticated) {
		logout();
	}

	return { userLoggedIn: loaded && authenticated };
}
