import _ from 'lodash';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

/**
	Specifies a filter based on the second part of the prop's name,
	split by '--'. Given a filter, returns an object.

	Filter examples:
		- $gte: { $gte: value }
		- $lte: { $lte: value }
		- oneday: { $gte: value, $lt: (value + one day) }
*/

const intervalDateFilters = (dateFilter, value) =>
	dateFilter === '$gte' || dateFilter === '$gt'
		? {
				[dateFilter]: moment(value).hours(0).minutes(0).toISOString(),
		  }
		: {
				[dateFilter]: moment(value).hours(23).minutes(59).toISOString(),
		  };

const transformDateByFilter = (dateFilter, value) =>
	dateFilter === 'oneday' || !dateFilter
		? {
				$gte: moment(value).hours(0).minutes(0).toISOString(),
				$lt: moment(value)
					.hours(0)
					.minutes(0)
					.add(1, 'day')
					.toISOString(),
		  }
		: intervalDateFilters(dateFilter, value);

/**
	Transforming filter keys and values by iterating and creating
	a new filter object.
	Values can be Date or anything else. If Date, we should
	take the filter into account.

	The date filter is in the key split by '--'.
	First part is the original prop name (eg. availability),
	second is the filter (eg. $lte, $gt, oneday)

	We should save the prop (including filters) with its original name
	and corresponsing values for queries.
	If a prop has two filters ($lt and $gt) at the same time, we extend it.
	(eg. availability--oneday -> availability)
*/
export const transformDate = (filter) => {
	const tFilter = {};
	/* eslint-disable */
	for (let key in filter) {
		const value = filter[key];
		if (moment(value, DATE_FORMAT, true).isValid()) {
			const originalPropName = key.split('--')[0];
			const dateFilter = key.split('--')[1];

			// for extension
			tFilter[originalPropName] = {
				...tFilter[originalPropName],
				...transformDateByFilter(dateFilter, value),
			};
		} else {
			tFilter[key] = value;
		}
	}
	return tFilter;
	/* eslint-enable */
};

export const transformArray = (filter) =>
	_.mapValues(filter, (value, key) =>
		value instanceof Array ? { $in: value } : value
	);

// Only works for 2 levels of nesting, maybe recursion is needed
export const transformNested = (filter) =>
	_.reduce(
		filter,
		(result, value, key) => {
			if (
				value instanceof Object &&
				!(value instanceof Array) &&
				_.first(_.keys(value)).charAt(0) !== '$'
			) {
				// return every key/value from object as new pair, not only the first
				return {
					...result,
					..._.mapKeys(value, (val, k) => `${key}.${k}`),
				};
			}
			return { ...result, [key]: value };
		},
		{}
	);
