import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';

/**
 * Displays finished package percent.
 *
 * If percent is 100% then transforms
 * into button with which workday can be closed or reopened
 */
const SummaryCircularProgressButton = ({ depoPercentage }) => {
	const classes = useStyles();
	const allPackageClosed = depoPercentage === 100;

	return (
		<>
			<CircularProgress
				size={48}
				thickness={4}
				className={`${classes.progress} dashboard-card--percentage-position`}
				variant="determinate"
				value={depoPercentage}
			/>

			<div className="dashboard-card--percentage-bg dashboard-card--percentage-position" />
			<div className="dashboard-card--percentage-text dashboard-card--percentage-position">
				{allPackageClosed ? (
					<Check />
				) : (
					<Typography
						className={classes.percentageText}
						variant="body2"
					>
						{depoPercentage}%
					</Typography>
				)}
			</div>
		</>
	);
};

const useStyles = makeStyles(() => ({
	percentageText: {
		fontWeight: 900,
	},
	progress: {
		zIndex: 2,
		position: 'absolute',
		right: '1rem',
		top: 'calc(1.5rem + 6px)',
	},
}));

export default SummaryCircularProgressButton;
