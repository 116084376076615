import { Layout } from 'react-admin';
import CustomMenu from './CustomMenu';
import { useLoadingRedirect } from './hooks';

const CustomLayout = (props) => {
	const { userLoggedIn } = useLoadingRedirect();

	// return null if token is not set throught checkAuth (no 401 error)
	if (!userLoggedIn) {
		return null;
	}

	return <Layout {...props} menu={CustomMenu} />;
};

export default CustomLayout;
