import axiosInstance from './axiosService';

const decodeJwt = (token) => {
	try {
		return JSON.parse(atob(token.split('.')[1]));
	} catch (e) {
		return null;
	}
};

const authProvider = {
	login: async ({ username, password }) => {
		const request = new Request('/login', {
			method: 'POST',
			body: JSON.stringify({ username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		const response = await fetch(request);
		if (response.status < 200 || response.status >= 300) {
			throw new Error(response.statusText);
		}

		const { token } = await response.json();

		localStorage.setItem('token', token);
		axiosInstance.refreshRequestHandler(token);
	},
	logout: (params) => {
		localStorage.removeItem('token');
		axiosInstance.refreshRequestHandler('');
		return Promise.resolve();
	},
	checkError: ({ status }) => {
		if (status === 401 || status === 403) {
			localStorage.removeItem('token');
			return Promise.reject();
		}
		return Promise.resolve();
	},
	// called when the user navigates to a new location, to check for authentication
	checkAuth: () => {
		const token = localStorage.getItem('token');

		if (token) {
			const decodedJwt = decodeJwt(token);

			// Token is expired
			if (Date.now() >= decodedJwt?.exp * 1000) {
				authProvider.logout();
				return Promise.resolve();
			}

			axiosInstance.refreshRequestHandler(token);
			return Promise.resolve();
		}
		return Promise.reject();
	},
	getPermissions: () => Promise.resolve(),
};

export default authProvider;
