import { List } from 'ra-ui-materialui';
import OperationReportListFilter from 'src/features/operation-report/components/filter/OperationReportListFilter';
import OperationReportFields from './OperationReportFields';

const OperationReportList = (props) => {
	return (
		<List
			{...props}
			title="Riport generálás"
			sort={{ field: 'createdAt', order: 'DESC' }}
			bulkActionButtons={false}
			exporter={false}
			filters={<OperationReportListFilter />}
		>
			<OperationReportFields {...props} />
		</List>
	);
};

export default OperationReportList;
