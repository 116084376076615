import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import SummaryCircularProgressButton from './SummaryCircularProgressButton';

const PackageSummaryCard = ({ data, partner }) => {
	const classes = useStyles();
	const { numberOfParcels, delivered, failedClosed, noDelivery } = data;

	const closed = delivered + failedClosed + noDelivery;
	const depoPercentage =
		closed > 0 ? Math.round((closed / numberOfParcels) * 100) : 0;

	const getPartnerClass = () => {
		switch (partner) {
			case 'EXO':
				return 'dashboard-card--header__exo';
			case 'Posta':
				return 'dashboard-card--header__posta';
			case 'DPD':
				return 'dashboard-card--header__dpd';
			default:
				return 'dashboard-card--header__main';
		}
	};
	const config = {
		title: partner || 'Összes',
		headerClass: getPartnerClass(),
	};
	return (
		<div className="dashboard-card">
			<Card className={classes.card}>
				<CardContent
					className={`${classes.progressCardContent} ${config.headerClass}`}
				>
					<Typography
						variant="h2"
						className={`${classes.progressCardHeadline} dashboard-card--header`}
					>
						{config.title} <span>| {numberOfParcels} db</span>
					</Typography>
					<SummaryCircularProgressButton
						classes={classes}
						depoPercentage={depoPercentage}
					/>
				</CardContent>
			</Card>
		</div>
	);
};

const useStyles = makeStyles(() => ({
	card: {
		marginBottom: '20px',
		borderRadius: 12,
		position: 'relative',
	},
	progressCardContent: { paddingBottom: '24px' },
	progressCardHeadline: { minWidth: '150px', color: 'white' },
}));

export default PackageSummaryCard;
