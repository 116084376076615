import { useMutation, useNotify } from 'ra-core';
import { useCallback } from 'react';

const useGetDepoParcels = () => {
	const notify = useNotify();
	const [mutate, { loaded, data }] = useMutation();

	const getDepoParcels = useCallback(
		(depo: string, params: any) => {
			mutate(
				{
					type: 'getDepoParcels',
					payload: { params, depo },
				},
				{
					onFailure: (error) => {
						notify(
							`Hiba: Nem sikerült betölteni a letöltéshez szükséges linket: ${error}`,
							'warning'
						);
					},
				}
			);
		},
		[mutate, notify]
	);

	return {
		getDepoParcels,
		loaded,
		parcels: data?.parcels || [],
		count: data?.count || 0,
	};
};

export default useGetDepoParcels;
