import { Box, makeStyles } from '@material-ui/core';

type CenteredLayoutProps = {
	children: React.ReactNode;
	mode: 'normal' | 'fullHeight';
};

type StyleProps = {
	height: string;
};

export default function CenteredLayout(props: CenteredLayoutProps) {
	const { children, mode = 'normal' } = props;

	const height = mode === 'fullHeight' ? '100vh' : '100%';
	const classes = useStyles({ height: height });

	return <Box className={classes.layout}>{children}</Box>;
}

const useStyles = makeStyles({
	layout: (props: StyleProps) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		height: props.height,
	}),
});
