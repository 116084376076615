import { Admin, Resource } from 'react-admin';
import CustomLayout from './custom-layout/CustomLayout';
import CustomLoginPage from './custom-layout/CustomLoginPage';
import CustomRoutes from './custom-routes/CustomRoutes';
import CreateOperationReport from './pages/operation-report/CreateOperationReport';
import OperationReportList from './pages/operation-report/OperationReportList';
import { authProvider, dataProvider } from './providers';
import { theme } from './theme';

const App = () => {
	return (
		<Admin
			theme={theme}
			authProvider={authProvider}
			dataProvider={dataProvider}
			layout={CustomLayout}
			loginPage={CustomLoginPage}
			customRoutes={CustomRoutes}
		>
			<Resource
				name="operation-reports"
				options={{ label: 'Riport generálás' }}
				list={OperationReportList}
				create={CreateOperationReport}
			/>
		</Admin>
	);
};

export default App;
