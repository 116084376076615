import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, SelectInput, TextInput } from 'react-admin';
import { Form } from 'react-final-form';

type ParcelSearchFilterProps = {
	onFilterChange: (values: any) => void;
};

export default function CourierSearchFilter(
	parcelSearchFilterProps: ParcelSearchFilterProps
) {
	const classes = useStyles();

	return (
		<Form onSubmit={parcelSearchFilterProps.onFilterChange}>
			{({ submitting, handleSubmit }) => (
				<Box display="flex" alignItems="center">
					<TextInput
						label="Keresés"
						source="search"
						alwaysOn
						resettable
						className={classes.filter}
					/>
					<SelectInput
						className={classes.filter}
						allowEmpty={false}
						source="is_active"
						defaultValue="true"
						choices={[
							{ id: 'true', name: 'Aktiv' },
							{ id: 'false', name: 'Inaktiv' },
							{ id: 'all', name: 'Összes' },
						]}
						label="Futár típusa"
						alwaysOn
					/>
					<Button
						variant="contained"
						disabled={submitting}
						label="Keresés"
						onClick={handleSubmit}
					/>
				</Box>
			)}
		</Form>
	);
}

const useStyles = makeStyles(() => ({
	filter: {
		marginTop: '24px',
		marginRight: '8px',
	},
}));
