import { DeliveryTypeField, OperationReportDateFields } from '../form-fields';
import OperationReportFormWrapper from './OperationReportFormWrapper';

const CardPaymentPayOffReportForm = (props) => {
	return (
		<OperationReportFormWrapper {...props}>
			<OperationReportDateFields />
			<DeliveryTypeField />
		</OperationReportFormWrapper>
	);
};

export default CardPaymentPayOffReportForm;
