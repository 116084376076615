import moment from 'moment';
import { Validator } from 'ra-core';

const datePrefix = moment().format('YYYY-MM-DD');

export const validateMomentTime: Validator = (time: string) => {
	return moment(`${datePrefix} ${time}`)?.isValid()
		? null
		: 'Helytelen dátum';
};
