const spanStyle = {
	marginLeft: 4
};

export default function ForwardIcon() {
	return (
		<span style={spanStyle} role="img" aria-label="Előre">
			➡️
		</span>
	);
}
