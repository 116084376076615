import { Validator } from 'ra-core';

export const mergeValidators = (
	validateProp: Validator | Validator[] = [],
	fieldValidators: Validator[] = []
) => {
	if (Array.isArray(validateProp)) {
		return [...validateProp, ...fieldValidators];
	}
	return [validateProp, ...fieldValidators];
};
