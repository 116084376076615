import MomentUtils from '@date-io/moment';
import {
	KeyboardTimePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import 'moment/locale/hu';
import { InputProps, useInput } from 'ra-core';
import { validateMomentTime, validateTimeFrame } from './validators';
import { mergeValidators } from './validators/utils';

const locale = 'hu';
moment.locale(locale);

type MaterialDateInputProps = InputProps<any> & {
	label: string;
	fullWidth: boolean;
};

export default function MaterialTimeInput(props: MaterialDateInputProps) {
	const { label, fullWidth, disabled } = props;

	const {
		input: { value, onChange, ...otherInputProps },
		meta: { touched, error, submitError },
	} = useInput({
		...props,
		validate: mergeValidators(props.validate, [
			validateMomentTime,
			validateTimeFrame,
		]),
	});

	const convertFieldValueToPickerValue = (fieldValue: string) => {
		if (!fieldValue) {
			return null;
		}
		const datePrefix = moment().format('YYYY-MM-DD');
		return moment(`${datePrefix} ${fieldValue}`, 'YYYY-MM-DD HH:mm', true);
	};

	const handleChange = (date: MaterialUiPickersDate) => {
		onChange(date?.format('HH:mm'));
	};

	return (
		<MuiPickersUtilsProvider
			utils={MomentUtils}
			locale={locale}
			libInstance={moment}
		>
			<KeyboardTimePicker
				disabled={disabled}
				value={convertFieldValueToPickerValue(value)}
				onChange={handleChange}
				{...otherInputProps}
				ampm={false}
				label={label}
				error={!!(touched && (error || submitError))}
				helperText={(touched && (error || submitError)) || ' '}
				fullWidth={fullWidth}
				inputVariant="filled"
				variant="inline"
			/>
		</MuiPickersUtilsProvider>
	);
}
