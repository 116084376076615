const spanStyle = {
	marginRight: 4
};

export default function CalendarIcon() {
	return (
		<span style={spanStyle} role="img" aria-label="Naptár">
			🗓
		</span>
	);
}
