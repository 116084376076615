export const CONTRACT_TYPE = {
	UNKNOWN: 'unknown',
	OCCASIONAL: 'occasional',
	MELODIAK: 'melodiak',
	YGENERACIO: 'ygeneracio',
	PRODIAK: 'prodiak',
	SCHONHERZ: 'schonherz',
	VALLALKOZO2W: 'vallalkozo2w',
	VALLALKOZO1M: 'vallalkozo1m',
	PROHUMAN: 'prohuman',
	LINESIX: 'linesix',
	BEVERTON: 'beverton',
	BUBBLELIFE: 'bubblelife',
	CUSTOM: 'custom',
	PERMANENT: 'permanent',
};

export const contractTypeTranslations = [
	{ id: CONTRACT_TYPE.UNKNOWN, name: 'Ismeretlen' },
	{ id: CONTRACT_TYPE.OCCASIONAL, name: 'Alkalmi bejelentett' },
	{ id: CONTRACT_TYPE.MELODIAK, name: 'Meló-Diák' },
	{ id: CONTRACT_TYPE.YGENERACIO, name: 'Y Generáció' },
	{ id: CONTRACT_TYPE.PRODIAK, name: 'ProDiák' },
	{ id: CONTRACT_TYPE.SCHONHERZ, name: 'Schönherz' },
	{ id: CONTRACT_TYPE.VALLALKOZO1M, name: 'Vállalkozó (/1M)' },
	{ id: CONTRACT_TYPE.VALLALKOZO2W, name: 'Vállalkozó (/2W)' },
	{ id: CONTRACT_TYPE.PROHUMAN, name: 'Prohumán' },
	{ id: CONTRACT_TYPE.LINESIX, name: 'Linesix' },
	{ id: CONTRACT_TYPE.BEVERTON, name: 'Beverton' },
	{ id: CONTRACT_TYPE.BUBBLELIFE, name: 'BubbleLife' },
	{ id: CONTRACT_TYPE.CUSTOM, name: 'Egyedi' },
	{ id: CONTRACT_TYPE.PERMANENT, name: 'Állandó futár' },
];
