import axios from 'axios';
import axiosService from 'src/providers/axiosService';

export const axiosWithLogout = axios.create();

axiosWithLogout.interceptors.response.use(
	(response) => response,
	(error) => {
		if (axios.isAxiosError(error)) {
			const unauthorized = error?.response?.status === 401;

			if (unauthorized) {
				axiosService.refreshRequestHandler('');
				localStorage.removeItem('token');
				window.location.href = '/#/login';
			}
		}
		throw error;
	}
);
