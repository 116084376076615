import { Create } from 'ra-ui-materialui';
import { useState } from 'react';
import {
	CardPaymentPayOffReportForm,
	OperationReportFormWrapper,
	PostaCardPaymentByDateReportForm,
} from 'src/features/operation-report/components/forms';
import { OperationReportType } from 'src/features/operation-report/operation-report-constants';

const getForm = (selectedReportType) => {
	switch (selectedReportType) {
		case OperationReportType.POSTA_CARD_PAYMENT_BY_DATE:
			return PostaCardPaymentByDateReportForm;
		case OperationReportType.CARD_PAYMENT_PAY_OFF_REPORT:
			return CardPaymentPayOffReportForm;
		default:
			return OperationReportFormWrapper;
	}
};

const CreateOperationReport = (props) => {
	const [selectedReportType, setSelectedReportType] = useState();
	const CorrespondingOperationReportForm = getForm(selectedReportType);

	return (
		<Create title="Riport létrehozása" {...props}>
			<CorrespondingOperationReportForm
				selectedReportType={selectedReportType}
				onSelectedReportTypeChange={(event) =>
					setSelectedReportType(event.target.value)
				}
			/>
		</Create>
	);
};

export default CreateOperationReport;
