import { SelectInput, SimpleForm, Toolbar } from 'ra-ui-materialui';
import { CategoryTitle } from 'src/features/courier/fields';
import { defaultValidation } from 'src/utils/app-utils';
import { operationReportTypes } from '../../operation-report-constants';

const OperationReportFormWrapper = ({
	children,
	onSelectedReportTypeChange,
	selectedReportType,
	...props
}) => {
	return (
		<SimpleForm
			{...props}
			redirect="/operation-reports"
			initialValues={{
				$gte: new Date(),
				$lte: new Date(),
				date: new Date(),
				postaGlobalPaymentsDate: new Date(),
				operationReportType: selectedReportType,
			}}
			toolbar={<Toolbar alwaysEnableSaveButton />}
		>
			<SelectInput
				onChange={onSelectedReportTypeChange}
				validate={defaultValidation()}
				source="operationReportType"
				choices={operationReportTypes}
				label="Riport típusa"
				fullWidth
			/>
			<CategoryTitle>Lekérdezés paraméterei</CategoryTitle>
			{children}
		</SimpleForm>
	);
};

export default OperationReportFormWrapper;
