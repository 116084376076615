import { makeStyles } from '@material-ui/core/styles';
import {
	BooleanField,
	DateField,
	EmailField,
	SelectField,
	SimpleShowLayout,
	TextField,
} from 'react-admin';
import { contractTypeTranslations, CONTRACT_TYPE } from './util/constants';

export const isCompany = (contractType: string) =>
	[
		CONTRACT_TYPE.VALLALKOZO2W,
		CONTRACT_TYPE.VALLALKOZO1M,
		CONTRACT_TYPE.PROHUMAN,
		CONTRACT_TYPE.LINESIX,
		CONTRACT_TYPE.BEVERTON,
		CONTRACT_TYPE.BUBBLELIFE,
		CONTRACT_TYPE.CUSTOM,
	].includes(contractType);

const ConditionalCourierPreviewData = (props: any) =>
	isCompany(props.record?.contractType) ? (
		<SimpleShowLayout className={props.className} {...props}>
			<strong>Céges adatok</strong>
			<TextField source="company_name" label="Cég neve" />
			<TextField source="tax_number" label="Cég adószáma" />
			<TextField source="company.location" label="Cég székhelye" />
			<TextField
				source="company_registration_number"
				label="Cég nyilvántartási száma"
			/>
		</SimpleShowLayout>
	) : (
		<SimpleShowLayout className={props.className} {...props}>
			<strong>Személyes adatok</strong>
			<TextField source="personal.location" label="Lakcím" />
			<TextField source="personal.placeOfBirth" label="Születési hely" />
			<DateField source="personal.dateOfBirth" label="Születési idő" />
			<TextField
				source="personal.taxIdentificationNumber"
				label="Adóazonosító jel"
			/>
			<TextField
				source="personal.socialSecurityNumber"
				label="TAJ szám"
			/>
			<TextField source="personal.mothersName" label="Anyja neve" />
		</SimpleShowLayout>
	);

export const PreviewCourierData = (props: any) => {
	const classes = useStyles();

	return (
		<>
			<SimpleShowLayout className={classes.group} {...props}>
				<strong>Alapadatok</strong>
				<TextField source="name" label="Név" />
				<TextField
					source="nickname"
					label="Becenév"
					emptyText="Ismeretlen"
				/>
				<TextField source="id" label="User ID" />
				<EmailField source="email" label="Email" />
				<TextField source="phone_number" label="Telefonszám" />
				<TextField source="mobileType" label="Telefon típus" />
				<TextField source="deliveryType" label="Szállítási eszköz" />
				<SelectField
					source="contractType"
					choices={contractTypeTranslations}
					label="Szerződés típusa"
				/>
				<BooleanField source="is_active" label="Aktív" />
				<BooleanField source="verified" label="E-mail megerősítés" />
			</SimpleShowLayout>
			<SimpleShowLayout className={classes.group} {...props}>
				<strong>Pénzügyi adatok</strong>
				<TextField source="bank_account" label="Bankszámlaszám" />
			</SimpleShowLayout>
			<ConditionalCourierPreviewData
				className={classes.group}
				{...props}
			/>
		</>
	);
};

const useStyles = makeStyles(() => ({
	field: {
		// These styles will ensure our drawer don't fully cover our
		// application when teaser or title are very long
		'& span': {
			display: 'inline-block',
			maxWidth: '20em',
		},
	},
	group: { margin: 10, background: '#f5f5f5', padding: 10 },
}));
