export enum OperationReportType {
	POSTA_CARD_PAYMENT_BY_DATE = 'postaCardPaymentByDate',
	CARD_PAYMENT_PAY_OFF_REPORT = 'cardPaymentPayOffReport',
}

export const operationReportTypesTranslations = {
	[OperationReportType.POSTA_CARD_PAYMENT_BY_DATE]:
		'Postai kártyas elszámolás adott napra',
	[OperationReportType.CARD_PAYMENT_PAY_OFF_REPORT]:
		'Kártyás elszámolás időszakra',
};

export const operationReportTypes = Object.entries(
	operationReportTypesTranslations
).map(([key, value]) => ({ id: key, name: value }));
