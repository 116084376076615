import MainSummaryStatistics from '../SummaryStatistics/MainSummaryStatistics';

export default function MainSummaryCard(props) {
	const { allParcels, partner } = props;

	const summedData = sumAllParcelData(allParcels);

	return <MainSummaryStatistics data={summedData} partner={partner} />;
}

function sumAllParcelData(allParcels) {
	return allParcels.reduce(
		(memo, item) => {
			return {
				cashOnDelivery: memo.cashOnDelivery + item.cashOnDelivery,
				delivered: memo.delivered + item.delivered,
				failed: memo.failed + item.failed,
				failedClosed: memo.failedClosed + item.failedClosed,
				fixed: memo.fixed + item.fixed,
				hasCourier: memo.hasCourier + item.hasCourier,
				imported: memo.imported + item.imported,
				noDelivery: memo.noDelivery + item.noDelivery,
				numberOfParcels: memo.numberOfParcels + item.numberOfParcels,
				fixedPricingModelCourierPrice:
					memo.fixedPricingModelCourierPrice +
					item.fixedPricingModelCourierPrice,
				dynamicPricingModelCourierPrice:
					memo.dynamicPricingModelCourierPrice +
					item.dynamicPricingModelCourierPrice,
				partnerPrice: memo.partnerPrice + item.partnerPrice,
			};
		},
		{
			cashOnDelivery: 0,
			delivered: 0,
			failed: 0,
			failedClosed: 0,
			fixed: 0,
			hasCourier: 0,
			imported: 0,
			noDelivery: 0,
			numberOfParcels: 0,
			fixedPricingModelCourierPrice: 0,
			dynamicPricingModelCourierPrice: 0,
			partnerPrice: 0,
		}
	);
}
