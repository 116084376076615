import { DeliveryType, LabeledValue } from './types';

export const PARCEL_STATUS = {
	CREATED: 0,
	BOOKED: 1,
	FIXED: 2,
	PICK_UP_FAILED: 3,
	TO_BE_RETURNED: 5,
	PICKED_UP: 6,
	DELIVERY_FAILED: 7,
	RETURNED: 8,
	NO_DELIVERY: 9,
	DELIVERED: 10,
};

export const IMPORT_METHOD = {
	WEB: 'manual-web',
};

export const READABLE_METHODS = {
	'manual-web': 'Webes felületről érkeztetve',
	'manual-mobile': 'Mobilról érkeztetve manuálisan',
	camera: 'Kód szkenneléssel érkeztetve',
};

export const USER_TYPE_WEBSHOP = 0;
export const USER_TYPE_COURIER = 1;

export const USER_TYPE = {
	SENDER: 0,
	COURIER: 1,
};

export const CONTRACT_TYPE = {
	DEFAULT: 'default',
	COMPANY: 'company',
	STUDENT: 'student',
};

export const COURIER_SALARY_STATUS = {
	CREATED: 'created',
	BILLABLE: 'billable',
	PAID: 'paid',
};

export const COURIER_SALARY_STATUS_TRANSLATIONS = {
	[COURIER_SALARY_STATUS.CREATED]: 'Létrehozva',
	[COURIER_SALARY_STATUS.BILLABLE]: 'Fizetendő',
	[COURIER_SALARY_STATUS.PAID]: 'Kifizetve',
};

// Timeslot related constants should be in `shared`, also used by `mobile`
const PARCEL_DELIVERY = 'PARCEL_DELIVERY';
const HOZDL = 'HOZDL';
const MORNING_HELP = 'MORNING_HELP';
const EXO_NIGHT = 'EXO_NIGHT';
const POSTA_NIGHT = 'POSTA_NIGHT';
const POSTA_MORNING = 'POSTA_MORNING';
const DPD_MORNING = 'DPD_MORNING';
const DPD_NIGHT = 'DPD_NIGHT';

export const timeslotTypes = {
	PARCEL_DELIVERY,
	HOZDL,
	MORNING_HELP,
	EXO_NIGHT,
	POSTA_NIGHT,
	POSTA_MORNING,
	DPD_MORNING,
	DPD_NIGHT,
};

export const timeslotTypeEmojis = {
	[PARCEL_DELIVERY]: '🚴‍♂️',
	[HOZDL]: '🛍️',
	[MORNING_HELP]: '📦',
	[EXO_NIGHT]: '🌃',
	[POSTA_NIGHT]: '🌃',
	[POSTA_MORNING]: '🌅',
	[DPD_NIGHT]: '🌃',
	[DPD_MORNING]: '🌅',
};

export const timeslotTypeLabels = {
	[PARCEL_DELIVERY]: 'Futárkodás',
	[HOZDL]: 'HozdL',
	[MORNING_HELP]: 'Reggeli segítség',
	[EXO_NIGHT]: 'EXO este',
	[POSTA_NIGHT]: 'Posta este',
	[POSTA_MORNING]: 'Posta reggel',
	[DPD_MORNING]: 'DPD reggel',
	[DPD_NIGHT]: 'DPD este',
};

export const timeslotChoices = [
	{ id: PARCEL_DELIVERY, name: '🚴‍♂️ Futárkodás' },
	{ id: HOZDL, name: '🛍️ HozdL' },
	{ id: MORNING_HELP, name: '📦 Reggeli segítség' },
	{ id: EXO_NIGHT, name: '🌃 EXO este' },
	{ id: POSTA_NIGHT, name: '🌃 Posta este' },
	{ id: POSTA_MORNING, name: '🌅 Posta reggel' },
	{ id: DPD_MORNING, name: '🌅 DPD reggel' },
	{ id: DPD_NIGHT, name: '🌃 DPD este' },
];

export const allListOption = { id: 'all', name: 'Összes' };

export const parcelStatusList = [
	allListOption,
	{ id: PARCEL_STATUS.NO_DELIVERY, name: 'Ma nem szállítjuk' },
	{ id: PARCEL_STATUS.CREATED, name: 'Beérkezett csomag' },
	{ id: PARCEL_STATUS.BOOKED, name: 'Útvonalhoz rendelve' },
	{ id: PARCEL_STATUS.FIXED, name: 'Útvonalhoz rendelve' },
	{ id: PARCEL_STATUS.PICK_UP_FAILED, name: 'Sikertelen felvétel' },
	{ id: PARCEL_STATUS.PICKED_UP, name: 'Kézbesítés alatt' },
	{ id: PARCEL_STATUS.DELIVERY_FAILED, name: 'Sikertelen kézbesítés' },
	{ id: PARCEL_STATUS.DELIVERED, name: 'Kézbesítve' },
	{ id: PARCEL_STATUS.TO_BE_RETURNED, name: 'Visszaszállítás alatt' },
	{ id: PARCEL_STATUS.RETURNED, name: 'Visszaszállítva' },
];

export const RatingsList = [
	{ id: 1, name: '1' },
	{ id: 2, name: '2' },
	{ id: 3, name: '3' },
];

export const parcelSizes = [
	{ id: 'S' },
	{ id: 'M' },
	{ id: 'L' },
	{ id: 'XL' },
];

export const senderTypes = [
	{ id: 'sameday', name: 'SameDay' },
	{ id: 'exo', name: 'EXO' },
	{ id: 'posta', name: 'Posta' },
	{ id: 'dpd', name: 'DPD' },
];

export const allParcelSenderTypes = [
	{ id: 'all', name: 'Összes' },
	{ id: 'sameday', name: 'SameDay' },
	{ id: 'exo', name: 'EXO' },
	{ id: 'posta', name: 'Posta' },
	{ id: 'dpd', name: 'DPD' },
	{ id: 'hozdl', name: 'Hozdl' },
];

export const contentTypes = [
	{ id: 'technical' },
	{ id: 'household' },
	{ id: 'beauty' },
	{ id: 'clothes' },
	{ id: 'medical' },
	{ id: 'fun' },
];

export const parcelValues = [
	{ id: '0', name: '10000' },
	{ id: '10000', name: '10000-20000' },
	{ id: '20000', name: '20000-30000' },
	{ id: '30000', name: '30000' },
];
export const postaAdminExceptionCodeList = [
	{ id: 'MK', name: 'MK - Második kézbesítésre visszaküld' },
	{ id: 'HK', name: 'HK - Postahelyi kézbesítésre visszaküld' },
	{ id: 'UVK', name: 'UVK - Véglegesen visszaküldendő küldemény' },
	{
		id: 'MARADVANY',
		name: 'MARADVANY - Kézbesítés megkísérlése nélkül vissza',
	},
	{ id: 'NINCSCSOMAG', name: 'NINCSCSOMAG - Nem érkezett csomag' },
	{ id: 'EGYEB', name: ' EGYEB - Egyéb okból visszaküldve' },
];
export const dpdAdminExceptionCodeList = [
	{
		id: '015',
		name: 'Címzett visszautasította az átvételt, vissza a feladónak',
	},
	{ id: '019', name: 'Címzett nem elérhető / első kísérlet' },
	{ id: '020', name: 'Címzett bejön érte a depóba' },
	{ id: '042', name: 'Címzett nem elérhető / második kísérlet' },
	{ id: 'NINCSCSOMAG', name: 'NINCSCSOMAG - Nem érkezett csomag' },
	{ id: 'EGYEB', name: ' EGYEB - Egyéb okból visszaküldve' },
];

export const exoAdminExceptionCodeList = [
	// only admin
	{ id: 'E19', name: 'E19 - ViddL raktárban maradt' },
	{ id: 'E22', name: 'E22 - EXO tévesen küldte a csomagot' },
	{ id: 'E45', name: 'E45 - EXO csomag nem érkezik meg, adat van' },
	{ id: 'E12', name: 'E12 - Nem elérhető, nem tartózkodik a címen' }, // courier and admin
	{ id: 'E91', name: 'E91 - Flexdel - kézbesítési cím módosítás' },
	{ id: 'E92', name: 'E92 - Flexdel - kézbesítési dátum módosítás' },
	{ id: 'V14', name: 'V14 - Nem elérhető (2. kísérlet után), telefonon sem' },
	{ id: 'V13', name: 'V13 - Mégsem kéri az árut' }, // courier and admin
	{ id: 'E53', name: 'E53 - Következő munkanapra kér kézbesítést' }, // courier and admin
	{ id: 'E99', name: 'E99 - Egyéb ok miatt' }, // courier and admin
	{ id: 'V01', name: 'V01 - Címzett ismeretlen, nem elérhető telefonon' }, // courier and admin
	{ id: 'E09', name: 'E09 - Megbízó utasítására' },
	{ id: 'E18', name: 'E18 - Nem jó futárhoz került a csomag' },
	{ id: 'E23', name: 'E23 - Okmányhiány miatt újra' },
	{ id: 'E27', name: 'E27 - Futár nem adott információt' },
	{ id: 'E41', name: 'E41 - EXO csomag megérkezik, de adat nincs' },
	{ id: 'E43', name: 'E43 - EXO átvételkor nem megfelelő a csomag' },
	{ id: 'E49', name: 'E49 - ViddL-nél eltűnt a csomag' },
	{ id: 'V09', name: 'V09 - Megbízó utasítására vissza' },
	{
		id: 'V43',
		name: 'V43 - Nem elérhető (2. kísérlet után), várakozási idő miatt',
	},
	{ id: 'V17', name: 'V17 - Árukár, rakodás során sérült' },
	{ id: 'V44', name: 'V44 - várakozási idő után nem vették át' },
	{ id: 'V91', name: 'V91 - Flexdel - utánvét probléma' },
	{ id: 'V92', name: 'V92 - Flexdel - nem kéri a szállítást' },
	{ id: 'V93', name: 'V93 - Flexdel - nem rendelte' },
	{ id: 'V94', name: 'V94 - Flexdel - egyéb' },
	{ id: 'V99', name: 'V99 - Egyéb' },
	// courier and admin
	{ id: 'E13', name: 'E13 - Nem elérhető, nyitvatartás után értem oda' },
	{ id: 'E14', name: 'E14 - Nem elérhető, ebédszünet' },
	{ id: 'E15', name: 'E15 - Nem elérhető, szabadság' },
	{ id: 'E16', name: 'E16 - Várakozás után nem vették át' },
	{ id: 'E03', name: 'E03 - Nem tud fizetni most, legközelebb tud' },
	{ id: 'V03', name: 'V03 - Nem tud fizetni, legközelebb sem fog' },
	{ id: 'V26', name: 'V26 - Csak bankkártyával fizet, legközelebb is' },
	{ id: 'E24', name: 'E24 - Más címre kér szállítást' },
	{ id: 'V04', name: 'V04 - Későbbi kiszállítást kér (5+ nap)' },
	{ id: 'V12', name: 'V12 - Nem rendelte az árut' },
	{ id: 'V21', name: 'V21 - Már korábban megkapta a csomagot' },
	{ id: 'V24', name: 'V24 - Túl későn érkezett a csomag, már nem kéri' },
	{ id: 'V25', name: 'V25 - Utánvét összege nem helyes' },
	{ id: 'V27', name: 'V27 - Termék minőségi kifogás' },
	{ id: 'V28', name: 'V28 - Termék mennyisági kifogás' },
	{ id: 'E17', name: 'E17 - Idő hiány miatt nem értem oda' },
	{ id: 'V31', name: 'V31 - Nem tud a megrendelésről' },
	{ id: 'V32', name: 'V32 - Megkapta már más futárszolgálattól' },
	{ id: 'V36', name: 'V36 - Nem megfelelő csomagolás, nem veszi át' },
	{ id: 'E38', name: 'E38 - Nem fizet utánvétet kibontás előtt, újra' },
	{ id: 'V37', name: 'V37 - Nem fizet utánvétet kibontás előtt, többet nem' },
	{ id: 'V22', name: 'V22 - Nem hajlandó aláírni a kézbesítést' },
	{ id: 'V23', name: 'V23 - Számlán szereplő cím rossz' },
	{ id: 'E25', name: 'E25 - Leltár van a kiszállítási címen' },
	{ id: 'E26', name: 'E26 - Nincs áruátvétel' },
	{ id: 'V10', name: 'V10 - Bélyegző/meghatalmazás hiánya miatt' },
	{ id: 'V35', name: 'V35 - Okmányhiány miatt többet nem' },
	{ id: 'E08', name: 'E08 - Baleset miatt újra' },
	{ id: 'V29', name: 'V29 - Termékkód rossz' },
	{ id: 'V08', name: 'V08 - Baleset miatt többet nem' },
	{ id: 'E28', name: 'E28 - Sérült küldemény, újra' },
	{ id: 'V16', name: 'V16 - Árukár, szállítás során megsérült' },
	{ id: 'V18', name: 'V18 - Árukár, részlegesen megsemmisült' },
	{ id: 'V19', name: 'V19 - Árukár, teljesen megsemmisült' },
	{ id: 'V20', name: 'V20 - Árukár, elveszett' },
	{ id: 'E01', name: 'E01 - Címzett ismeretlen, de elérhető telefonon' },
	{ id: 'E02', name: 'E02 - Cím nem létezik, de elérhető telefonon' },
	{ id: 'V02', name: 'V02 - Cím nem létezik' },
	{ id: 'V30', name: 'V30 - Címzett meghalt' },
	{ id: 'E07', name: 'E07 - Műszaki hiba' },
	{ id: 'E05', name: 'E05 - Természeti akadály miatt újra' },
	{ id: 'V05', name: 'V05 - Természeti akadály miatt többet nem' },
	{ id: 'E06', name: 'E06 - Hatósági intézkedés miatt újra' },
	{ id: 'V06', name: 'V06 - Hatósági intézkedés miatt többet nem' },
];

export const userTypes = [
	{ id: USER_TYPE_WEBSHOP, name: 'Sender' },
	{ id: USER_TYPE_COURIER, name: 'Courier' },
];

export const contractTypeTypes = [
	{ id: CONTRACT_TYPE.DEFAULT, name: 'Alapértelmezett' },
	{ id: CONTRACT_TYPE.COMPANY, name: 'Cég/EV' },
	{ id: CONTRACT_TYPE.STUDENT, name: 'Diák' },
];
export const discountTypes = [
	{ id: 0, name: 'No' },
	{ id: 1, name: 'Normal' },
];

export const etikettsBucketUrl =
	'https://s3.console.aws.amazon.com/s3/buckets/viddl-etiketts';
export const awsLoginUrl = 'https://013096176224.signin.aws.amazon.com/console';

export const pickUpTimeSlots = [
	{ id: '9-12', name: '9-12' },
	{ id: '12-15', name: '12-15' },
	{ id: '15-18', name: '15-18' },
	{ id: '18-21', name: '18-21' },
	{ id: '9-18', name: '9-18' },
	{ id: '9-21', name: '9-21' },
];

export const dropOffTimeSlots = [
	{ id: '9-12', name: '9-12' },
	{ id: '12-15', name: '12-15' },
	{ id: '15-18', name: '15-18' },
	{ id: '18-21', name: '18-21' },
	{ id: '9-18', name: '9-18' },
];

export const startHourChoices = [
	{ id: 9, name: '9:00' },
	{ id: 12, name: '12:00' },
	{ id: 15, name: '15:00' },
	{ id: 18, name: '18:00' },
];

export const roundNumberChoices = [
	{ id: 1, name: '1. kör' },
	{ id: 2, name: '2. kör' },
	{ id: 3, name: '3. kör' },
];

export const refetchLimitInMilliseconds = 60 * 1000;

export const deliveryTypes: LabeledValue<DeliveryType | 'all'>[] = [
	{ id: 'all', name: 'Összes' },
	{ id: DeliveryType.EXO, name: 'EXO' },
	{ id: DeliveryType.POSTA, name: 'Posta' },
	{ id: DeliveryType.DPD, name: 'DPD' },
];

export const depos = {
	BUDAPEST: 'budapest',
	GYOR: 'gyor',
	DEBRECEN: 'debrecen',
	NYIREGYHAZA: 'nyiregyhaza',
	SZEKESFEHERVAR: 'szekesfehervar',
	KECSKEMET: 'kecskemet',
	MISKOLC: 'miskolc',
	SZEGED: 'szeged',
};

export const depoCityNames = {
	[depos.BUDAPEST]: 'Budapest',
	[depos.GYOR]: 'Győr',
	[depos.DEBRECEN]: 'Debrecen',
	[depos.NYIREGYHAZA]: 'Nyíregyháza',
	[depos.SZEKESFEHERVAR]: 'Székesfehérvár',
	[depos.KECSKEMET]: 'Kecskemét',
	[depos.MISKOLC]: 'Miskolc',
	[depos.SZEGED]: 'Szeged',
};
