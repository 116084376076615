import { ArrowDownward } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Button, FunctionField } from 'ra-ui-materialui';
import { useState } from 'react';
import useGetReportDownloadUrl from '../../hooks/useGetReportDownloadLink';

const OperationReportDownloadButton = (props) => {
	const classes = useStyles();
	const [
		upToDateDownloadUrlAvailable,
		setUpToDateDownloadUrlAvailable,
	] = useState(false);

	const {
		handleGetReportDownloadUrl,
		loading,
		data,
	} = useGetReportDownloadUrl(() => setUpToDateDownloadUrlAvailable(true));

	const handleOpenDownloadLink = () => {
		window.open(data.downloadUrl, '_blank', 'noopener,noreferrer');
		setUpToDateDownloadUrlAvailable(false);
	};

	const requestDownloadUrlButton = ({ _id }) => (
		<Button
			className={classes.button}
			onClick={() => handleGetReportDownloadUrl(_id)}
			disabled={loading}
			variant="outlined"
			label="Link létrehozása"
		>
			<ArrowDownward />
		</Button>
	);

	const downloadOperationReportButton = () => (
		<Button
			className={classes.button}
			onClick={handleOpenDownloadLink}
			variant="contained"
			label="Letöltés"
		>
			<ArrowDownward />
		</Button>
	);

	return (
		<FunctionField
			{...props}
			render={
				upToDateDownloadUrlAvailable
					? downloadOperationReportButton
					: requestDownloadUrlButton
			}
		/>
	);
};

const useStyles = makeStyles(() => ({
	button: {
		textTransform: 'none',
		fontSize: '14px',
		lineHeight: 'unset',
		width: '100%',
		maxWidth: '185px',
	},
}));

export default OperationReportDownloadButton;
