import { Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Button, SelectInput, TextInput } from 'react-admin';
import { Form } from 'react-final-form';
import { MaterialDateInput } from 'src/components/inputs';
import { defaultValidation } from 'src/utils/app-utils';
import { DEFAULT_AVAILABILITY_DURATION } from './util/constants';

type ParcelSearchFilterProps = {
	onFilterChange: (values: any) => void;
};

const dateValidator = (_: any, allValues: any) => {
	if (
		moment(allValues.$lte).diff(moment(allValues?.$gte), 'month', true) < 6
	) {
		return undefined;
	}

	if (moment(allValues.$lte).isAfter(allValues.$gte)) {
		return undefined;
	}

	return 'A kezdeti dátumn és végdátum között nem telhet el 6 hónap.';
};

export default function ParcelSearchFilter(
	parcelSearchFilterProps: ParcelSearchFilterProps
) {
	const classes = useStyles();

	return (
		<Form onSubmit={parcelSearchFilterProps.onFilterChange}>
			{({
				submitting,
				handleSubmit,
				values: { availabilityDuration },
			}) => (
				<Box display="flex" alignItems="center">
					<TextInput
						label="Keresés"
						source="search"
						alwaysOn
						resettable
						className={classes.filter}
					/>
					{availabilityDuration === 'custom' && (
						<>
							<MaterialDateInput
								source="$gte"
								label="Kezdeti dátum"
								validate={[defaultValidation(), dateValidator]}
								className={classes.filter}
							/>
							<MaterialDateInput
								source="$lte"
								label="Vég dátum"
								validate={[defaultValidation(), dateValidator]}
								className={classes.filter}
							/>
						</>
					)}
					<SelectInput
						className={classes.filter}
						source="availabilityDuration"
						label="Szállítási nap"
						initialValue={DEFAULT_AVAILABILITY_DURATION}
						choices={[
							{ id: 'today', name: 'Ma' },
							{ id: 'yesterday', name: 'Tegnap' },
							{ id: '5', name: '5 nap' },
							{ id: '10', name: '10 nap' },
							{ id: '1m', name: '1 hónap' },
							{ id: '6m', name: '6 hónap' },
							{ id: 'custom', name: 'Egyéni' },
						]}
						alwaysOn
						allowEmpty={false}
					/>
					<Button
						variant="contained"
						disabled={submitting}
						label="Keresés"
						onClick={handleSubmit}
					/>
				</Box>
			)}
		</Form>
	);
}

const useStyles = makeStyles((theme) => ({
	filter: {
		marginTop: '24px',
		marginRight: '8px',
	},
}));
