import palette from './palette';

const typography = {
	h2: {
		fontWeight: 900,
		fontSize: '21px',
		color: 'black',
	},
	h3: {
		fontWeight: 600,
		fontSize: '20px',
	},
	body2: {
		fontSize: '14px',
		color: 'black',
	},
	caption: {
		fontWeight: 400,
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		color: 'grey',
	},
	button: {
		color: palette.primary.main,
	},
};

export default typography;
