import moment from 'moment';
import { Validator } from 'ra-core';

const datePrefix = moment().format('YYYY-MM-DD');

export const validateTimeFrame: Validator = (time: string) => {
	const dateToValidate = moment(`${datePrefix} ${time}`);
	const timeFrameStart = moment(`${datePrefix} 06:00`);
	const timeFrameEnd = moment(`${datePrefix} 21:00`);
	if (
		dateToValidate.isAfter(timeFrameStart) &&
		dateToValidate.isBefore(timeFrameEnd)
	) {
		return null;
	}
	return 'Írj be egy időpontot 6 és 21 óra között, ÓÓ:PP formátumban';
};
