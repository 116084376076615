import axios, { AxiosInstance } from 'axios';

// there is no root api prefix (ie.: /api) at the moment
export const API_DOMAIN = '/';

class Axios {
	instance: AxiosInstance;

	constructor() {
		this.instance = axios.create({
			baseURL: API_DOMAIN,
			headers: {
				Authorization: 'Bearer ',
			},
		});
	}

	refreshRequestHandler(token: string) {
		this.instance = axios.create({
			baseURL: API_DOMAIN,
			headers: {
				Authorization: `Bearer ${token}`,
				// Accept: 'application/application-json',
				// 'Content-type': 'application/json',
			},
		});
	}
}

export default new Axios();
