import { fetchUtils } from 'ra-core';

export const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}

	const token = localStorage.getItem('token');

	options.user = {
		authenticated: true,
		token: `Bearer ${token}`,
	};

	return fetchUtils.fetchJson(url, options);
};
