import { MaterialDateInput } from 'src/components/inputs';
import { defaultValidation } from 'src/utils/app-utils';

const OperationReportSingleDateField = () => (
	<MaterialDateInput
		source="date"
		label="Lekérdezés dátuma"
		validate={defaultValidation()}
		fullWidth
	/>
);

export default OperationReportSingleDateField;
