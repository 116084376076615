export type LabeledValue<T> = { id: T; name: string };

export enum DeliveryType {
	EXO = 'exo',
	POSTA = 'posta',
	DPD = 'dpd',
	/**
	 * @deprecated
	 */
	HOZDL = 'hozdl',
}

export enum SpecialDeliveryType {
	OTHER = 'OTHER',
	LATE_DELIVERY = 'LATE_DELIVERY',
	WEEKEND_DELIVERY = 'WEEKEND_DELIVERY',
}

export enum CompensationType {
	BONUS = 'BONUS',
	DEDUCTION = 'DEDUCTION',
}
export interface Address {
	title: string;
	lat: string;
	lng: string;
}

export interface ParcelStatus {
	code: number;
}

export interface Parcel {
	_id: string;
	drop_off_address: Address;
	recipient_name: string;
	status: ParcelStatus;
}
