import _ from 'lodash';
import _moment from 'moment';
import { extendMoment } from 'moment-range';
import { required } from 'react-admin';
import { PARCEL_STATUS } from './constants';

const moment = extendMoment(_moment);

function calculatePickupAndDropoffDateTimes(record) {
	const pickUpStop = record.pick_up_stop || {};
	const dropOffStop = record.drop_off_stop || {};
	const availability = record.availability
		? moment(record.availability).format('YYYY.MM.DD')
		: 'Nincs adat';
	const calculateDropOffInterval = () => {
		if (record.deliveryType === 'dpd') {
			return {
				date: moment(dropOffStop.selected_time_slot).format(
					'YYYY.MM.DD'
				),
				hour: `${moment(dropOffStop.selected_time_slot)
					.subtract(30, 'minutes')
					.format('HH:mm')}
					-
					${moment(dropOffStop.selected_time_slot)
						.add(30, 'minutes')
						.format('HH:mm')} (${moment(dropOffStop.eta).format(
					'H:mm'
				)})`,
			};
		}
		return {
			date: moment(dropOffStop.selected_time_slot).format('YYYY.MM.DD'),
			hour: `${moment(dropOffStop.selected_time_slot)
				.subtract(60, 'minutes')
				.format('HH:mm')}}-${moment(dropOffStop.selected_time_slot)
				.add(60, 'minutes')
				.format('HH:mm')} (${moment(dropOffStop.eta).format('H:mm')})`,
		};
	};
	if ([PARCEL_STATUS.CREATED].indexOf(record.status.code) !== -1) {
		return {
			pickup: {
				date: availability,
				hour: '',
			},
			dropoff: {
				date: availability,
				hour: '',
			},
		};
	}
	if (
		[
			PARCEL_STATUS.BOOKED,
			PARCEL_STATUS.FIXED,
			PARCEL_STATUS.NO_DELIVERY,
		].indexOf(record.status.code) !== -1
	) {
		return {
			pickup: {
				date: moment(pickUpStop.selected_time_slot).format(
					'YYYY.MM.DD'
				),
				hour: `${moment(pickUpStop.selected_time_slot).format(
					'H'
				)}-${moment(pickUpStop.selected_time_slot)
					.add(3, 'h')
					.format('H')} (${moment(pickUpStop.eta).format('H:mm')})`,
			},
			dropoff: calculateDropOffInterval(),
		};
	}
	if (
		[PARCEL_STATUS.PICK_UP_FAILED, PARCEL_STATUS.PICKED_UP].indexOf(
			record.status.code
		) !== -1
	) {
		return {
			pickup: {
				date: moment(record.pickup_up_date).format('YYYY.MM.DD'),
				hour: moment(record.pickup_up_date).format('HH:mm'),
			},
			dropoff: calculateDropOffInterval(),
		};
	}
	if (
		[
			PARCEL_STATUS.TO_BE_RETURNED,
			PARCEL_STATUS.DELIVERY_FAILED,
			PARCEL_STATUS.RETURNED,
			PARCEL_STATUS.DELIVERED,
		].indexOf(record.status.code) !== -1
	) {
		return {
			pickup: {
				date: moment(record.pickup_up_date).format('YYYY.MM.DD'),
				hour: moment(record.pickup_up_date).format('HH:mm'),
			},
			dropoff: {
				date: moment(record.delivery_date).format('YYYY.MM.DD'),
				hour: moment(record.delivery_date).format('HH:mm'),
			},
		};
	}
	return {
		pickup: {
			date: 'NaN',
			hour: 'NaN',
		},
		dropoff: {
			date: 'NaN',
			hour: 'NaN',
		},
	};
}

const calculateChoiceRanges = (record) => {
	const today = moment().startOf('day');
	const firstDate = moment(
		_.first(_.first(record.transports).pick_up_time_slots)
	).startOf('day');
	const lastDate = moment(
		_.first(_.last(record.transports).pick_up_time_slots)
	).startOf('day');

	if (today.isAfter(lastDate)) {
		// Today is after last transport date
		return [
			...moment.range(firstDate, lastDate).by('day'),
			...moment.range(today, moment(today).add(5, 'days')).by('day'),
		];
	}
	if (today.isAfter(firstDate)) {
		// Today is in between first and last transport date
		return [
			...moment
				.range(firstDate, moment(lastDate).add(5, 'days'))
				.by('day'),
		];
	}
	// Today is before first transport date
	return [...moment.range(today, moment(lastDate).add(5, 'days')).by('day')];
};

const getTransportChoices = (record) => {
	if (!record || !record.transports) return [];

	const dates = calculateChoiceRanges(record);

	return dates.map((date) => ({
		id: date.format('YYYY.MM.DD'),
	}));
};

const getTransportDates = (record) => {
	if (!record || !record.transports) return [];

	const transports = _.map(record.transports, (transport) =>
		moment(_.first(transport.pick_up_time_slots))
	);
	return transports.map((date) => date.format('YYYY.MM.DD'));
};

const getTransportPickUpTime = (record) => {
	if (!record || !record.transports) return [];

	const firstTransport = _.first(record.transports);

	const pickUpHourFrom = moment(_.first(firstTransport.pick_up_time_slots));
	const pickUpHourTo = moment(_.last(firstTransport.pick_up_time_slots));

	return `${pickUpHourFrom.format('H')}-${pickUpHourTo
		.add(3, 'h')
		.format('H')}`;
};

const getTransportDropOffTime = (record) => {
	if (!record || !record.transports) return [];

	const firstTransport = _.first(record.transports);

	const dropOffHourFrom = moment(_.first(firstTransport.drop_off_time_slots));
	const dropOffHourTo = moment(_.last(firstTransport.drop_off_time_slots));

	return `${dropOffHourFrom.format('H')}-${dropOffHourTo
		.add(3, 'h')
		.format('H')}`;
};

/**
 * remove given properties from object
 *
 * @param {Object} objectToBeFiltered objectToBeFiltered
 * @param {Array} validProps not needed properties on object
 * @returns {Object} object without the given propertioes
 */

const filterObjectByProps = (objectToBeFiltered, invalidProperties) => {
	const reducer = (accumulated, prop) =>
		invalidProperties.includes(prop)
			? accumulated
			: Object.assign(accumulated, { [prop]: objectToBeFiltered[prop] });

	return Object.keys(objectToBeFiltered).reduce(reducer, {});
};

const getColorByPercentage = (percent) => {
	switch (Math.floor(percent / 10)) {
		case 0:
			return '#FF0000';
		case 1:
			return '#FF3400';
		case 2:
			return '#FF6900';
		case 3:
			return '#FF9E00';
		case 4:
			return '#FFD300';
		case 5:
			return '#F7FF00';
		case 6:
			return '#C2FF00';
		case 7:
			return '#8DFF00';
		case 8:
			return '#58FF00';
		case 9:
			return '#24FF00';
		default:
			return '#00FF00';
	}
};

export const defaultValidation = (message = 'Kötelező mező') =>
	required(message);

export {
	getColorByPercentage,
	calculatePickupAndDropoffDateTimes,
	getTransportChoices,
	getTransportDates,
	getTransportPickUpTime,
	getTransportDropOffTime,
	filterObjectByProps,
};
