import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import { BackIcon, CalendarIcon, ForwardIcon } from '../../../components/icons';
import {
	selectDashboardCurrentDate,
	useDashboardStore,
} from '../zustand/store';

const styles = {
	containerStyle: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
	backStyle: {
		flex: 1,
	},
	calendarStyle: {
		flex: 2,
		textAlign: 'center',
	},
	datePicker: {
		flex: 2,
		textAlign: 'center',
	},
	forwardStyle: {
		flex: 1,
		textAlign: 'right',
	},
};

function DashboardControls(props) {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

	const currentDate = useDashboardStore(selectDashboardCurrentDate);
	const {
		fetchDailyDashboard,
		nextDay,
		previousDay,
		resetDay,
		dashboardSelectDay,
	} = useDashboardStore();
	// fetches dashboard data on date change
	// date is stored in store, no need to pass it
	useEffect(() => {
		fetchDailyDashboard();
	}, [fetchDailyDashboard, currentDate]);

	const onNextDay = () => {
		nextDay();
	};

	const onPreviousDay = () => {
		previousDay();
	};

	const onResetDay = () => {
		resetDay();
	};

	return (
		<div style={styles.containerStyle}>
			<div style={styles.backStyle}>
				<Button variant="text" color="primary" onClick={onPreviousDay}>
					<BackIcon /> Hátra
				</Button>
			</div>
			<div style={styles.calendarStyle}>
				<Button variant="text" color="primary" onClick={onResetDay}>
					<CalendarIcon /> Váltás mai napra
				</Button>
			</div>
			<div style={styles.datePicker}>
				<Button
					variant="text"
					color="primary"
					onClick={() => setIsDatePickerOpen(true)}
				>
					<CalendarIcon />
					Dátum kiválasztása
				</Button>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DatePicker
						style={{ display: 'none' }}
						value={currentDate}
						onChange={(date) => {
							const formattedDate = date.format('YYYY-MM-DD');
							dashboardSelectDay(formattedDate);
							setIsDatePickerOpen(false);
						}}
						variant="dialog"
						onClose={() => setIsDatePickerOpen(false)}
						open={isDatePickerOpen}
					/>
				</MuiPickersUtilsProvider>
			</div>
			<div style={styles.forwardStyle}>
				<Button variant="text" color="primary" onClick={onNextDay}>
					Előre <ForwardIcon />
				</Button>
			</div>
		</div>
	);
}

export default DashboardControls;
