import MomentUtils from '@date-io/moment';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import 'moment/locale/hu';
import { InputProps, useInput } from 'ra-core';
import { useMemo } from 'react';
import { validateMomentDate } from './validators';
import { mergeValidators } from './validators/utils';

const locale = 'hu';
moment.locale(locale);

type MaterialDateInputProps = InputProps<any> & {
	label: string;
	fullWidth?: boolean;
	disabled?: boolean;
	className?: string;
};

const DATE_FORMAT = 'YYYY-MM-DD';

export default function MaterialDateInput(props: MaterialDateInputProps) {
	const { label, fullWidth = false, disabled = false, className } = props;

	const {
		input: { value, onChange, ...otherInputProps },
		meta: { touched, error, submitError },
	} = useInput({
		...props,
		validate: mergeValidators(props.validate, [validateMomentDate]),
	});

	const handleChange = (date: MaterialUiPickersDate) => {
		onChange(date?.format(DATE_FORMAT));
	};

	const transformedValue = useMemo(() => moment(value), [value]);

	return (
		<MuiPickersUtilsProvider
			utils={MomentUtils}
			locale={locale}
			libInstance={moment}
		>
			<KeyboardDatePicker
				className={className}
				disabled={disabled}
				value={transformedValue}
				onChange={handleChange}
				{...otherInputProps}
				label={label}
				format={DATE_FORMAT}
				error={!!(touched && (error || submitError))}
				helperText={(touched && (error || submitError)) || ' '}
				fullWidth={fullWidth}
				inputVariant="filled"
				variant="inline"
			/>
		</MuiPickersUtilsProvider>
	);
}
