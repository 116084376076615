import { ReactElement } from 'react';

const spanStyle = {
	marginRight: 4,
};

export default function BackIcon(): ReactElement {
	return (
		<span style={spanStyle} role="img" aria-label="Hátra">
			⬅️
		</span>
	);
}
