import produce from 'immer';
import moment from 'moment';
import create, { StateCreator } from 'zustand';
import { Parcel } from '../../../utils/types';
import { axiosWithLogout } from './axiosWithLogout';

interface Daily {
	flexdelCount: number;
	budapest: {
		exo: Parcel[];
		posta: Parcel[];
		dpd: Parcel[];
	};
	gyor: {
		posta: Parcel[];
	};
	debrecen: {
		posta: Parcel[];
	};
	szekesfehervar: {
		posta: Parcel[];
	};
	kecskemet: {
		posta: Parcel[];
	};
	nyiregyhaza: {
		posta: Parcel[];
	};
	szeged: {
		posta: Parcel[];
	};
	miskolc: {
		posta: Parcel[];
	};
}

interface DashboardStore {
	loading: boolean;
	daily: Daily;
	currentDate: string;
	currentDateFetched: boolean;

	fetchDailyDashboard: () => Promise<void>;

	startPending: () => void;
	nextDay: () => void;
	resetDay: () => void;
	previousDay: () => void;
	dashboardSelectDay: (date: string) => void;
}

const immer =
	<T extends DashboardStore>(config: StateCreator<T>): StateCreator<T> =>
	(set, get, api) =>
		config(
			(partial, replace) => {
				const nextState =
					typeof partial === 'function'
						? produce(partial as (state: T) => T)
						: (partial as T);
				return set(nextState, replace);
			},
			get,
			api
		);

const isoDateFormat = 'YYYY-MM-DD';
const getInitialDate = () => moment().format(isoDateFormat);
const initialState = {
	loading: false,
	daily: {
		flexdelCount: 0,
		budapest: {
			exo: [],
			posta: [],
			dpd: [],
		},
		gyor: {
			posta: [],
		},
		debrecen: {
			posta: [],
		},
		kecskemet: {
			posta: [],
		},
		nyiregyhaza: {
			posta: [],
		},
		szekesfehervar: {
			posta: [],
		},
		szeged: {
			posta: [],
		},
		miskolc: {
			posta: [],
		},
	},
	currentDate: getInitialDate(),
	currentDateFetched: false,
};

const getAxiosOptions = () => ({
	headers: {
		Accept: 'application/application-json',
		Authorization: `Bearer ${localStorage.getItem('token')}`,
		'Content-type': 'application/json',
	},
});

export const useDashboardStore = create(
	immer((set, get) => ({
		...initialState,
		fetchDailyDashboard: async () => {
			const state = get();
			try {
				const { data }: { data: Daily } = await axiosWithLogout.get(
					`/dashboard/daily/${state.currentDate}`,
					getAxiosOptions()
				);

				set({
					loading: false,
					currentDateFetched: true,
					daily: data,
				});
			} catch (error) {
				set({
					loading: false,
				});

				throw error;
			}
		},
		startPending: async () => {
			set({
				loading: true,
			});
		},
		nextDay: async () => {
			set({
				currentDate: moment(get().currentDate)
					.add(1, 'days')
					.format(isoDateFormat),
				currentDateFetched: false,
			});
		},
		resetDay: () => set({ currentDate: getInitialDate() }),
		previousDay: () => {
			set({
				currentDate: moment(get().currentDate)
					.subtract(1, 'days')
					.format(isoDateFormat),
				currentDateFetched: false,
			});
		},
		dashboardSelectDay: (date: string) => set({ currentDate: date }),
	}))
);

export const selectDashboardCurrentDate = (state: DashboardStore) =>
	state.currentDate;
export const selectDashboardDaily = (state: DashboardStore) => state.daily;
export const isDateToday = (date: string) => date === getInitialDate();
