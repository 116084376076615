import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import 'moment/locale/hu';
import { InputProps, useInput } from 'ra-core';
import { useMemo } from 'react';
import { validateMomentDate } from './validators';
import { mergeValidators } from './validators/utils';

const locale = 'hu';
moment.locale(locale);

type MaterialDateInputProps = InputProps<any> & {
	label: string;
};

const DATE_FORMAT = 'YYYY-MM-DD';

export default function MaterialDateFilter(props: MaterialDateInputProps) {
	const { label } = props;

	const {
		input: { value, onChange, ...otherInputProps },
		meta: { touched, error, submitError },
	} = useInput({
		...props,
		validate: mergeValidators(props.validate, [validateMomentDate]),
	});

	const handleChange = (date: MaterialUiPickersDate) => {
		onChange(date?.format(DATE_FORMAT));
	};

	const transformedValue = useMemo(() => (value ? moment(value) : null), [
		value,
	]);

	return (
		<MuiPickersUtilsProvider
			utils={MomentUtils}
			locale={locale}
			libInstance={moment}
		>
			<DatePicker
				size="small"
				style={{ marginBottom: 8 }}
				value={transformedValue}
				onChange={handleChange}
				{...otherInputProps}
				label={label}
				format={DATE_FORMAT}
				error={!!(touched && (error || submitError))}
				helperText={null}
				clearable
				inputVariant="filled"
				variant="dialog"
			/>
		</MuiPickersUtilsProvider>
	);
}
