import {
	DateField,
	Labeled,
	SimpleShowLayout,
	TextField,
	TextInput,
} from 'react-admin';
import MaterialDateInput from 'src/components/inputs/MaterialDateInput';
import { CONTRACT_TYPE } from 'src/utils/constants';

const LabeledTextField = ({ label, source, record, ...rest }) => (
	<Labeled label={label}>
		<TextField record={record} source={source} {...rest} />
	</Labeled>
);

export const ConditionalCompanyTextField = (props) =>
	props.record && props.record.contractType === CONTRACT_TYPE.COMPANY ? (
		<LabeledTextField {...props} />
	) : null;

export const ConditionalCompanyTextInput = (props) =>
	props.record && props.record.contractType === CONTRACT_TYPE.COMPANY ? (
		<TextInput {...props} />
	) : null;

export const ConditionalStudentTextField = (props) =>
	props.record && props.record.contractType !== CONTRACT_TYPE.COMPANY ? (
		<LabeledTextField {...props} />
	) : null;

export const ConditionalStudentTextInput = (props) =>
	props.record && props.record.contractType !== CONTRACT_TYPE.COMPANY ? (
		<TextInput {...props} />
	) : null;

export const ConditionalStudentDateField = (props) =>
	props.record && props.record.contractType !== CONTRACT_TYPE.COMPANY ? (
		<Labeled label={props.label}>
			<DateField {...props} />
		</Labeled>
	) : null;

export const ConditionalStudentDateInput = (props) =>
	props.record && props.record.contractType !== CONTRACT_TYPE.COMPANY ? (
		<MaterialDateInput isRequired={false} {...props} />
	) : null;

export const ConditionalCourierPreviewData = (props) =>
	props.record && props.record.contractType !== CONTRACT_TYPE.COMPANY ? (
		<SimpleShowLayout className={props.className} {...props}>
			<strong>Személyes adatok</strong>
			<TextField source="personal.location" label="Lakcím" />
			<TextField source="personal.placeOfBirth" label="Születési hely" />
			<DateField source="personal.dateOfBirth" label="Születési idő" />
			<TextField
				source="personal.taxIdentificationNumber"
				label="Adóazonosító jel"
			/>
			<TextField
				source="personal.socialSecurityNumber"
				label="TAJ szám"
			/>
			<TextField source="personal.mothersName" label="Anyja neve" />
		</SimpleShowLayout>
	) : (
		<SimpleShowLayout className={props.className} {...props}>
			<strong>Céges adatok</strong>
			<TextField source="company_name" label="Cég neve" />
			<TextField source="tax_number" label="Cég adószáma" />
			<TextField source="company.location" label="Cég székhelye" />
			<TextField
				source="company_registration_number"
				label="Cég nyilvántartási száma"
			/>
		</SimpleShowLayout>
	);

export const CategoryTitle = (props) => <strong>{props.children}</strong>;
