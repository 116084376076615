import {
	Datagrid,
	DateField,
	FunctionField,
	TextField,
} from 'ra-ui-materialui';
import OperationReportDownloadButton from 'src/features/operation-report/components/fields/OperationReportDownloadButton';
import { operationReportTypesTranslations } from 'src/features/operation-report/operation-report-constants';

const OperationReportFields = (props) => {
	return (
		<Datagrid {...props}>
			<TextField label="Azonosító" source="_id" />
			<FunctionField
				render={({ reportType }) =>
					operationReportTypesTranslations[reportType]
				}
				label="Riport típusa"
			/>
			<DateField
				showTime
				locales={['hu']}
				label="Dátum"
				source="createdAt"
			/>
			<TextField label="Készítő" source="reportCreatorName" />
			<OperationReportDownloadButton />
		</Datagrid>
	);
};

export default OperationReportFields;
