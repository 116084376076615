import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import TableElementPreviewButton from 'src/components/TableElementPreviewButton';
import { depoCityNames } from 'src/utils/constants';
import useGetDepoCouriers from './hooks/useGetDepoCouriers';
import { PreviewCourierData } from './PreviewCourierData';
import { contractTypeTranslations } from './util/constants';

const ROWS_PER_PAGE = 5;

const translateContractType = (contractType: string) => {
	const traslation = contractTypeTranslations.find(
		({ id }) => id === contractType
	);

	return traslation?.name ?? '';
};

type DepoCourierTableProps = {
	depo: string;
	filters: any;
};

export default function DepoCourierTable(
	depoCourierTableProps: DepoCourierTableProps
) {
	const { depo, filters } = depoCourierTableProps;
	const [pageNumber, setPageNumber] = useState(0);
	const { couriers, count, getDepoCouriers } = useGetDepoCouriers();

	useEffect(() => {
		getDepoCouriers(depo, {
			...filters,
			skip: pageNumber * ROWS_PER_PAGE,
			limit: ROWS_PER_PAGE,
		});
	}, [depo, filters, getDepoCouriers, pageNumber]);

	const handlePageChange = (_: unknown, newPageNumber: number) => {
		setPageNumber(newPageNumber);

		getDepoCouriers(depo, {
			...filters,
			skip: newPageNumber * ROWS_PER_PAGE,
			limit: ROWS_PER_PAGE,
		});
	};

	return (
		<>
			<Typography variant="h3" paragraph>
				{depoCityNames[depo]}
			</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Név</TableCell>
							<TableCell>Szerződés típusa</TableCell>
							<TableCell>Telefonszám</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Szállítási eszköz</TableCell>
							<TableCell>Telefon típus</TableCell>
							<TableCell>Aktív</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{couriers.map((courier: any) => (
							<TableRow key={courier._id}>
								<TableCell>{courier.name}</TableCell>
								<TableCell>
									{translateContractType(
										courier.contractType
									)}
								</TableCell>
								<TableCell>{courier.phone_number}</TableCell>
								<TableCell>{courier.email}</TableCell>
								<TableCell>{courier.deliveryType}</TableCell>
								<TableCell>{courier.mobileType}</TableCell>
								<TableCell>{courier.is_active}</TableCell>
								<TableCell>
									<TableElementPreviewButton
										component={PreviewCourierData}
										record={courier}
										depo={depo}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[]}
								colSpan={3}
								count={count}
								rowsPerPage={ROWS_PER_PAGE}
								page={pageNumber}
								onPageChange={handlePageChange}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
}
