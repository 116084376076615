import {
	Box,
	Card,
	CardContent,
	Grid,
	makeStyles,
	Typography,
} from '@material-ui/core';

const MainSummaryDetails = ({ data }) => {
	const classes = useStyles();
	const {
		delivered,
		failed,
		failedClosed,
		hasCourier,
		fixed,
		imported,
		noDelivery,
		cashOnDelivery,
		fixedPricingModelCourierPrice,
		dynamicPricingModelCourierPrice,
		partnerPrice,
	} = data;

	const accuracy = Math.round((delivered / (delivered + failed)) * 100 || 0);
	const deliveryPercentage = Math.round(
		((delivered + failed) / hasCourier) * 100 || 0
	);
	const profitWithFixedPriceModel =
		partnerPrice - fixedPricingModelCourierPrice;
	const profitWithDynamicPriceModel =
		partnerPrice - dynamicPricingModelCourierPrice;

	return (
		<Box
			maxWidth={900}
			minWidth={600}
			className={classes.detailsCardContent}
		>
			<Card className={classes.card}>
				<CardContent className={classes.detailsCardContent}>
					<Grid container align="center">
						<Grid item xs={2}>
							<Typography variant="caption">Utánvét</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{cashOnDelivery.toLocaleString('fr')} Ft
								</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">Szállítás</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong> {deliveryPercentage}%</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">Sikeres</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{delivered} / {accuracy}%
								</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">
								Sikertelen / Lezárt
							</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{failed} / {failedClosed}
								</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">
								Kiszállítás alatt:
							</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong> {fixed}</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">Raktárban</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong> {imported + noDelivery}</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">
								Fix futár fizetés árazás
							</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{fixedPricingModelCourierPrice.toLocaleString(
										'fr'
									)}{' '}
									Ft
								</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">
								Dinamikus futár fizetés
							</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{dynamicPricingModelCourierPrice.toLocaleString(
										'fr'
									)}{' '}
									Ft
								</strong>
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography variant="caption">
								Partner árazás
							</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{partnerPrice.toLocaleString('fr')} Ft
								</strong>
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography variant="caption">
								Különbség fix árazás esetén
							</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{profitWithFixedPriceModel.toLocaleString(
										'fr'
									)}{' '}
									Ft
								</strong>
							</Typography>
						</Grid>
						<Grid item xs={3}>
							<Typography variant="caption">
								Különbség dinamikus árazás esetén
							</Typography>
							<Typography variant="h2" className={classes.h2}>
								<strong>
									{profitWithDynamicPriceModel.toLocaleString(
										'fr'
									)}{' '}
									Ft
								</strong>
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	card: {
		marginBottom: '20px',
		borderRadius: 12,
		position: 'relative',
		width: '100%',
	},
	detailsCardContent: {
		display: 'flex',
		flexGrow: 1,
		// for overwriting default mui styles
		'&:last-child': {
			paddingBottom: '8px',
		},
	},
	h2: {
		marginTop: '6px',
	},
}));

export default MainSummaryDetails;
