import { makeStyles } from '@material-ui/core';
import {
	FunctionField,
	ReferenceInput,
	required,
	SelectInput,
	Validator,
} from 'react-admin';
import { courierDefaults, UserRecord } from './UserInput';

type CourierUserInputProps = {
	source?: string;
	withEmail?: boolean; // true by default
	fullWidth?: boolean; // true by default
	alwaysOn?: boolean; // false by default
	skipValidation?: boolean; // false by default
	validate?: Validator | Validator[] | undefined; //  validation is enabled by default
	filter?: {
		is_active?: boolean;
		user_type: 0 | 1;
	};
};

export default function CourierUserInput(props: CourierUserInputProps) {
	const classes = useStyles();

	const {
		withEmail = true,
		fullWidth = true,
		skipValidation = false,
		validate = required('Válassz ki egy futárt!'),
		source = '_courier',
	} = props;

	const validateIfEnabled = skipValidation ? undefined : validate;

	return (
		<ReferenceInput
			{...courierDefaults}
			source={source}
			{...props}
			validate={validateIfEnabled}
		>
			<SelectInput
				fullWidth={fullWidth}
				// TODO: someone should look into this
				// @ts-ignore
				classes={classes}
				optionText={
					withEmail ? (
						<FunctionField<UserRecord>
							render={(record) =>
								`${record?.name} (${record?.email})`
							}
						/>
					) : (
						'name'
					)
				}
			/>
		</ReferenceInput>
	);
}

const useStyles = makeStyles(() => ({
	chip: {
		display: 'contents',
	},
}));
