import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import Info from '@material-ui/icons/Info';
import { FunctionComponent, useState } from 'react';
import { Button } from 'react-admin';

export type TableElementPreviewButtonProps = {
	component: FunctionComponent<{ record: any; depo: string }>;
	record: any;
	depo: string;
};

export default function TableElementPreviewButton(
	props: TableElementPreviewButtonProps
) {
	const WrappedComponent = props.component;

	const [showPanel, setShowPanel] = useState(false);

	const handleClick = () => {
		setShowPanel(true);
	};

	const handleCloseClick = () => {
		setShowPanel(false);
	};

	return (
		<>
			<Button onClick={handleClick} label="">
				<Info />
			</Button>
			<Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
				<div style={{ textAlign: 'right' }}>
					<Button label="" onClick={handleCloseClick}>
						<CloseIcon />
					</Button>
				</div>
				<WrappedComponent record={props.record} depo={props.depo} />
			</Drawer>
		</>
	);
}
