import { Box, makeStyles, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import moment from 'moment';
import { MaterialDateInput } from 'src/components/inputs';
import { defaultValidation } from 'src/utils/app-utils';

const startDateValidator = (value, allValues) => {
	if (moment(value).isSameOrBefore(moment(allValues?.$lte), 'day')) {
		return undefined;
	}

	return 'A kezdeti dátumnak korábban vagy azon a napon kell lennie mint a végdátum.';
};

const endDateValidator = (value, allValues) => {
	if (moment(value).isSameOrAfter(moment(allValues?.$gte), 'day')) {
		return undefined;
	}

	return 'A végdátumnak korábban vagy azon a napon kell lennie mint a kezdeti dátum.';
};

const OperationReportDateFields = () => {
	const classes = useStyles();

	return (
		<>
			<Box display="flex" alignItems="center" mt={1}>
				<ErrorOutline className={classes.icon} />
				<Typography className={classes.tooltip}>
					A kezdeti és vég dátum is beleszámít a lekérdezésbe, tehát
					ha egy napot akarsz lekérdezni akkor állítsd mind a két
					napot arra az egy dátumra.
				</Typography>
			</Box>
			<Box display="flex" mt={1}>
				<MaterialDateInput
					source="$gte"
					label="Kezdeti dátum"
					validate={[defaultValidation(), startDateValidator]}
					className={classes.input}
				/>
				<MaterialDateInput
					source="$lte"
					label="Vég dátum"
					validate={[defaultValidation(), endDateValidator]}
					className={classes.input}
				/>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	input: {
		flex: 1,
		'&:first-of-type': {
			marginRight: theme.spacing(4),
		},
	},
	tooltip: {
		fontSize: '13px',
		marginLeft: theme.spacing(1),
		lineHeight: 'initial',
	},
	icon: {
		fontSize: '17px',
	},
	error: {
		color: '#f44336',
		letterSpacing: '0.03333em',
		fontSize: '0.75rem',
	},
}));

export default OperationReportDateFields;
