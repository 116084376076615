import { Divider, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { CenteredLayout } from '../../../../components/layout';
import {
	selectDashboardCurrentDate,
	useDashboardStore,
} from '../../zustand/store';
import MainSummaryCard from './MainSummaryCard';

function DashboardContent(props) {
	const { classes } = props;

	const { daily, loading, currentDateFetched, startPending } =
		useDashboardStore();

	const currentDate = useDashboardStore(selectDashboardCurrentDate);

	useEffect(() => {
		startPending();
	}, [currentDate, startPending]);

	const allParcelsBudapest = [
		...(daily?.budapest?.exo ?? []),
		...(daily?.budapest?.posta ?? []),
		...(daily?.budapest?.dpd ?? []),
		...(daily?.budapest?.hozdl ?? []),
	];

	const allParcelsGyor = [
		...(daily?.gyor?.exo ?? []),
		...(daily?.gyor?.posta ?? []),
		...(daily?.gyor?.dpd ?? []),
		...(daily?.gyor?.hozdl ?? []),
	];

	const allParcelsDebrecen = [
		...(daily?.debrecen?.exo ?? []),
		...(daily?.debrecen?.posta ?? []),
		...(daily?.debrecen?.dpd ?? []),
		...(daily?.debrecen?.hozdl ?? []),
	];

	const allParcelsKecskemet = [
		...(daily?.kecskemet?.exo ?? []),
		...(daily?.kecskemet?.posta ?? []),
		...(daily?.kecskemet?.dpd ?? []),
		...(daily?.kecskemet?.hozdl ?? []),
	];

	const allParcelsNyiregyhaza = [
		...(daily?.nyiregyhaza?.exo ?? []),
		...(daily?.nyiregyhaza?.posta ?? []),
		...(daily?.nyiregyhaza?.dpd ?? []),
		...(daily?.nyiregyhaza?.hozdl ?? []),
	];
	const allParcelsSzekesfehervar = [
		...(daily?.szekesfehervar?.exo ?? []),
		...(daily?.szekesfehervar?.posta ?? []),
		...(daily?.szekesfehervar?.dpd ?? []),
		...(daily?.szekesfehervar?.hozdl ?? []),
	];

	const allParcelsMiskolc = [
		...(daily?.miskolc?.exo ?? []),
		...(daily?.miskolc?.posta ?? []),
		...(daily?.miskolc?.dpd ?? []),
		...(daily?.miskolc?.hozdl ?? []),
	];

	const allParcelsSzeged = [
		...(daily?.szeged?.exo ?? []),
		...(daily?.szeged?.posta ?? []),
		...(daily?.szeged?.dpd ?? []),
		...(daily?.szeged?.hozdl ?? []),
	];

	if (loading && !currentDateFetched) {
		return (
			<CenteredLayout>
				<CircularProgress size={48} />
			</CenteredLayout>
		);
	}

	if (
		[
			...allParcelsBudapest,
			...allParcelsDebrecen,
			...allParcelsGyor,
			...allParcelsKecskemet,
			...allParcelsNyiregyhaza,
			...allParcelsSzekesfehervar,
			...allParcelsMiskolc,
			...allParcelsSzeged,
		].length === 0
	) {
		return (
			<CenteredLayout>
				<img src="/dashboard/no_delivery.png" alt="No delivery" />
				<div style={{ textAlign: 'center' }}>
					<Typography variant="h3" gutterBottom>
						A mai nap nem történt szállítás.
					</Typography>
					<Typography variant="body1">
						Válassz ki egy másik napot.
					</Typography>
				</div>
			</CenteredLayout>
		);
	}

	return (
		<>
			<div className={classes.body}>
				<MainSummaryCard
					allParcels={[
						...allParcelsBudapest,
						...allParcelsDebrecen,
						...allParcelsGyor,
						...allParcelsKecskemet,
						...allParcelsNyiregyhaza,
						...allParcelsSzekesfehervar,
						...allParcelsMiskolc,
						...allParcelsSzeged,
					]}
					partner="Összes depó"
				/>
				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard
					allParcels={allParcelsBudapest}
					partner="Budapest"
				/>
				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard allParcels={allParcelsGyor} partner="Győr" />

				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard
					allParcels={allParcelsDebrecen}
					partner="Debrecen"
				/>
				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard
					allParcels={allParcelsSzekesfehervar}
					partner="Szekesfehervar"
				/>

				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard
					allParcels={allParcelsKecskemet}
					partner="Kecskemet"
				/>
				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard
					allParcels={allParcelsNyiregyhaza}
					partner="Nyiregyhaza"
				/>
				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard
					allParcels={allParcelsSzeged}
					partner="Szeged"
				/>
				<Divider style={{ margin: '1em' }} />
				<MainSummaryCard
					allParcels={allParcelsMiskolc}
					partner="Miskolc"
				/>
			</div>
		</>
	);
}

const styles = () => ({
	body: {
		padding: '1em',
		display: 'flex',
		flexDirection: 'column',
	},
});

export default withStyles(styles)(DashboardContent);
