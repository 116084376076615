import {
	DashboardContent,
	DashboardControls,
	DashboardHeadline,
	DashboardProgress,
} from './components';
import './dashboard.css';
import {
	isDateToday,
	selectDashboardCurrentDate,
	useDashboardStore,
} from './zustand/store';

function DashboardAllDepo() {
	const currentDate = useDashboardStore(selectDashboardCurrentDate);
	const _isDateToday = isDateToday(currentDate);

	return (
		<>
			{_isDateToday && <DashboardProgress />}
			<DashboardControls />
			<DashboardHeadline />
			<DashboardContent />
		</>
	);
}

export default DashboardAllDepo;
