import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TableElementPreviewButton from 'src/components/TableElementPreviewButton';
import { depoCityNames } from 'src/utils/constants';
import useGetDepoParcels from './hooks/useGetDepoParcels';
import { PreviewParcelData } from './PreviewParcelData';
import { ROWS_PER_PAGE } from './util/constants';

type DepoParcelTableProps = {
	depo: string;
	filters: any;
};

const formatRecipient = (name: string, company: string) => {
	if (!name && company) {
		return company;
	} else if (name && company) {
		return name === company ? name : `${name} (${company})`;
	}
	return `${name} ${company}`;
};

export default function DepoParcelTable(
	depoParcelTableProps: DepoParcelTableProps
) {
	const { depo, filters } = depoParcelTableProps;
	const [pageNumber, setPageNumber] = useState(0);
	const { parcels, count, getDepoParcels } = useGetDepoParcels();

	useEffect(() => {
		getDepoParcels(depo, {
			...filters,
			skip: pageNumber * ROWS_PER_PAGE,
			limit: ROWS_PER_PAGE,
		});
	}, [depo, filters, getDepoParcels, pageNumber]);

	const handlePageChange = (_: unknown, newPageNumber: number) => {
		setPageNumber(newPageNumber);

		getDepoParcels(depo, {
			...filters,
			skip: newPageNumber * ROWS_PER_PAGE,
			limit: ROWS_PER_PAGE,
		});
	};

	return (
		<>
			<Typography variant="h3" paragraph>
				{depoCityNames[depo]}
			</Typography>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Azonosító</TableCell>
							<TableCell>Kézbesítési ETA</TableCell>
							<TableCell>Kézbesítési időpont</TableCell>
							<TableCell>Kézbesítési cím</TableCell>
							<TableCell>Címzett</TableCell>
							<TableCell>Utánvét</TableCell>
							<TableCell>Státusz</TableCell>
							<TableCell>Kivétel kód / megjegyzés</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{parcels.map((parcel: any) => (
							<TableRow key={parcel._id}>
								<TableCell>{parcel._id}</TableCell>
								<TableCell>
									{parcel?.drop_off_stop?.eta
										? moment(
												parcel.drop_off_stop.eta
										  ).format('YYYY.MM.DD HH:mm')
										: 'Nincs adat'}
								</TableCell>
								<TableCell>
									{parcel?.delivery_date
										? moment(parcel.delivery_date).format(
												'YYYY. MM. DD. HH:mm:ss'
										  )
										: 'Nincs adat'}
								</TableCell>
								<TableCell>
									{parcel.drop_off_address.title}
								</TableCell>
								<TableCell>
									{formatRecipient(
										parcel.recipient_name,
										parcel.recipient_company
									)}
								</TableCell>
								<TableCell>
									{parcel.cashOnDelivery
										? `${parcel.cashOnDelivery} Ft`
										: 'nincs'}
								</TableCell>
								<TableCell>{parcel.status.code}</TableCell>
								<TableCell>{`${
									parcel.status.exception || ''
								} / ${parcel.status.note || ''}`}</TableCell>
								<TableCell>
									<TableElementPreviewButton
										component={PreviewParcelData}
										record={parcel}
										depo={depo}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[]}
								colSpan={3}
								count={count}
								rowsPerPage={ROWS_PER_PAGE}
								page={pageNumber}
								onPageChange={handlePageChange}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
}
