import { Typography } from '@material-ui/core';
import moment from 'moment';
import {
	selectDashboardCurrentDate,
	useDashboardStore,
} from '../zustand/store';

const dateFormat = 'YYYY. MM. DD.';
const dateStyle: React.CSSProperties = {
	fontWeight: 'normal',
	fontSize: '18px',
};

export default function DashboardHeadline() {
	const currentDate = useDashboardStore(selectDashboardCurrentDate);

	const formattedDate = moment(currentDate).format(dateFormat);

	return (
		<Typography variant="h2">
			Csomagok <span style={dateStyle}>({formattedDate})</span>
		</Typography>
	);
}
