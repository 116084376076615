import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	DateField,
	FunctionField,
	SelectField,
	SimpleShowLayout,
	TextField,
} from 'react-admin';
import { parcelStatusList } from '../../utils/constants';
import { ParentChildParcelField } from './ParentChildParcelField';

const useStyles = makeStyles(() => ({
	field: {
		// These styles will ensure our drawer don't fully cover our
		// application when teaser or title are very long
		'& span': {
			display: 'inline-block',
			maxWidth: '20em',
		},
	},
	group: { margin: 10, background: '#f5f5f5', padding: 10 },
}));

export const PreviewParcelData = (props: any) => {
	const { record, depo } = props;
	const classes = useStyles();

	return (
		<>
			<SimpleShowLayout className={classes.group} {...props}>
				<Typography variant="h5">Szállítási adatok</Typography>
				{record.externalParcelId && (
					<TextField
						label="Külső csomag azonosító"
						source="externalParcelId"
					/>
				)}
				{record.externalCollectiveId && (
					<TextField
						label="Külső kollektív csomag azonosító"
						source="externalCollectiveId"
					/>
				)}
				<SelectField
					source="status.code"
					choices={parcelStatusList}
					label="Státusz"
				/>
				<FunctionField
					source="Kivétel kód / Megjegyzés"
					render={(record: any) =>
						`${record.status.exception ?? ''} / ${
							record.status.note ?? ''
						}`
					}
				/>
				<TextField label="Csomagtípus" source="deliveryType" />
				<DateField label="Rendelkezésre állás" source="availability" />
				<TextField label="Utánvét" source="cashOnDelivery" />
				{record.signedRecipientName && (
					<FunctionField
						label="Átvevő neve (aláírás esetén)"
						render={(record: any) =>
							decodeURI(record.signedRecipientName)
						}
					/>
				)}
				{record.paidWithCard && (
					<FunctionField
						source="Kártyás fizetés státusza"
						render={(record: any) =>
							`${record.paidWithCard.ST} (ID: ${record.paidWithCard.TI})`
						}
					/>
				)}
			</SimpleShowLayout>
			<SimpleShowLayout className={classes.group} {...props}>
				<Typography variant="h5">Csomagtörténet</Typography>
				<ParentChildParcelField parcel={record} depo={depo} />
			</SimpleShowLayout>
		</>
	);
};
