import { SelectInput } from 'ra-ui-materialui';
import { defaultValidation } from 'src/utils/app-utils';
import { DeliveryType } from 'src/utils/types';

export const deliveryTypes = [
	{ id: 'all', name: 'Összes' },
	{ id: DeliveryType.EXO, name: 'EXO' },
	{ id: DeliveryType.POSTA, name: 'Posta' },
	{ id: DeliveryType.DPD, name: 'DPD' },
	{ id: 'exoAndPosta', name: 'Exo és Posta' },
];

const DeliveryTypeField = () => (
	<SelectInput
		defaultValue="all"
		validate={defaultValidation()}
		source="deliveryType"
		choices={deliveryTypes}
		label="Csomag típus"
		fullWidth
	/>
);

export default DeliveryTypeField;
