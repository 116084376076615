import { createTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';

const theme = createTheme({
	palette,
	typography,
});

export default theme;
