import { Assessment, Dashboard, Search } from '@material-ui/icons';
import { MenuItemLink } from 'react-admin';
import { withRouter } from 'react-router-dom';

const marginTopStyle = { marginTop: '2rem' };

const CustomMenu = ({ onMenuClick }) => {
	return (
		<div style={marginTopStyle}>
			<MenuItemLink
				to="/dashboard"
				primaryText="Dashboard"
				onClick={onMenuClick}
				leftIcon={<Dashboard />}
			/>
			<MenuItemLink
				to="/operation-reports"
				primaryText="Riport generálás"
				onClick={onMenuClick}
				leftIcon={<Assessment />}
			/>
			<MenuItemLink
				to="/parcel-search"
				primaryText="Csomag keresés"
				onClick={onMenuClick}
				leftIcon={<Search />}
			/>
			<MenuItemLink
				to="/courier-search"
				primaryText="Futár keresés"
				onClick={onMenuClick}
				leftIcon={<Search />}
			/>
		</div>
	);
};

export default withRouter(CustomMenu);
