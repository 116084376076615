import { withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

const CustomSubmenu = ({
	name,
	children,
	classes,
	underlineColor,
	underlineHeight,
}) => (
	<div className={classes.container}>
		<Typography className={classes.title}>
			<span
				style={{
					borderBottom: `${underlineHeight}px solid ${underlineColor}`,
				}}
			>
				{name}
			</span>
		</Typography>
		<Collapse in timeout="auto" unmountOnExit>
			<List component="div" disablePadding>
				{children}
			</List>
		</Collapse>
	</div>
);

const styles = () => ({
	container: {
		paddingTop: 8,
		paddingBottom: 8,
	},
	title: {
		paddingLeft: 16,
		color: '#9c9c9c',
		textTransform: 'uppercase',
		fontSize: '0.75rem',
		fontWeight: 500,
	},
});

export default withStyles(styles)(CustomSubmenu);
