import { Filter, SelectInput } from 'ra-ui-materialui';
import React from 'react';
import { operationReportTypes } from '../../operation-report-constants';

const OperationReportListFilter = (props) => {
	return (
		<Filter {...props}>
			<SelectInput
				source="reportType"
				choices={operationReportTypes}
				label="Riport típusa"
				alwaysOn
			/>
		</Filter>
	);
};

export default OperationReportListFilter;
