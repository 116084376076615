import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
import { transformArray, transformDate, transformNested } from './utils';

const convertSortParams = (sort) => {
	if (sort) {
		const { field, order } = sort;

		const fieldOrMongoId = field === 'id' ? '_id' : field;
		const mongoStyleOrder = order === 'DESC' ? -1 : 1;

		return { [fieldOrMongoId]: mongoStyleOrder };
	}
	return null;
};

const convertGetListParams = (params) => {
	const { page, perPage } = params.pagination;

	const sort = convertSortParams(params.sort);

	const rangeStart = (page - 1) * perPage;

	const transformedFilter = transformDate(
		transformArray(transformNested(params.filter))
	);

	const query = {
		sort: JSON.stringify(sort),
		skip: rangeStart,
		limit: perPage,
		filter: JSON.stringify(transformedFilter),
	};

	return query;
};

const convertGetManyParams = (params) => {
	const sort = convertSortParams(params.sort);

	const query = {
		sort: JSON.stringify(sort),
		filter: JSON.stringify({ _id: { $in: params.ids } }),
	};

	return query;
};

const checkContentRangeHeader = (headers) => {
	if (!headers.has('content-range')) {
		throw new Error(
			'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
		);
	}
};

export const createViddLAdminRestDataProvider = (
	apiUrl,
	httpClient = fetchUtils.fetchJson
) => ({
	getList: async (resource, params) => {
		const query = convertGetListParams(params);

		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		const { json, headers } = await httpClient(url);

		checkContentRangeHeader(headers);

		return {
			data: json.map((record) => ({ id: record._id, ...record })),
			total: parseInt(headers.get('content-range'), 10),
		};
	},

	getOne: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
			data: { id: json._id, ...json },
		})),

	getMany: async (resource, params) => {
		const query = convertGetManyParams(params);

		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		const { json, headers } = await httpClient(url);

		checkContentRangeHeader(headers);

		return {
			data: json.map((record) => ({ id: record._id, ...record })),
			total: parseInt(headers.get('content-range'), 10),
		};
	},

	getManyReference: () =>
		Promise.reject('getManyReference is not implemented!'),

	update: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;

		const options = {
			method: 'PUT',
			body: JSON.stringify(params.data),
		};

		return httpClient(url, options).then(({ json }) => ({
			data: { id: json._id, ...json },
		}));
	},

	updateMany: () => Promise.reject('updateMany is not implemented!'),

	create: (resource, params) => {
		const url = `${apiUrl}/${resource}`;

		const options = {
			method: 'POST',
			body: JSON.stringify(params.data),
		};

		return httpClient(url, options).then(({ json }) => ({
			data: { id: json._id, ...json },
		}));
	},

	delete: (resource, params) => {
		const url = `${apiUrl}/${resource}/${params.id}`;

		const options = {
			method: 'DELETE',
		};

		return httpClient(url, options).then(({ json }) => ({
			data: json,
		}));
	},

	deleteMany: (resource, params) => {
		const body = {
			selectedIds: params.ids,
		};
		return httpClient(`${apiUrl}/${resource}`, {
			method: 'DELETE',
			body: JSON.stringify(body),
		}).then(({ json }) => ({ data: json }));
	},

	getParcelHistory: (_resource, params) => {
		return httpClient(
			`/parcel-search/parcel-history/${params.depo}/${params.id}`,
			{
				method: 'GET',
			}
		).then(({ json }) => ({ data: json }));
	},

	getParcel: (_resource, params) => {
		return httpClient(`${apiUrl}/parcel/${params.depo}/${params.id}`, {
			method: 'GET',
		}).then(({ json }) => ({ data: json }));
	},

	getReportDownloadUrl: async ({ id }) => {
		const { json } = await httpClient(
			`/operation-reports/${id}/get-download-url`,
			{
				method: 'GET',
			}
		);

		return { data: json };
	},

	getDepoParcels: async ({ depo, params }) => {
		const { json } = await httpClient(`/parcel-search/${depo}`, {
			method: 'POST',
			body: JSON.stringify(params),
		});

		return { data: json };
	},

	getDepoCouriers: async ({ depo, params }) => {
		const { json } = await httpClient(`/courier-search/${depo}`, {
			method: 'POST',
			body: JSON.stringify(params),
		});

		return { data: json };
	},
});
