import { MaterialDateInput } from 'src/components/inputs';
import { defaultValidation } from 'src/utils/app-utils';

const PostaGlobalPaymentsDateField = () => (
	<MaterialDateInput
		source="postaGlobalPaymentsDate"
		label="Global Payments dátuma"
		validate={defaultValidation()}
		fullWidth
	/>
);

export default PostaGlobalPaymentsDateField;
