import LinearProgress from '@material-ui/core/LinearProgress';
import useDashboardFetching from './useDashboardFetching';

const progressStyle = { marginBottom: 12 };

function DashboardProgress(props) {
	// triggers a render every second
	const { progress } = useDashboardFetching();

	return (
		<LinearProgress
			variant="determinate"
			value={progress}
			style={progressStyle}
		/>
	);
}

export default DashboardProgress;
