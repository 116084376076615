import { makeStyles } from '@material-ui/core';
import {
	FunctionField,
	ReferenceArrayInput,
	ReferenceInput,
	SelectArrayInput,
	SelectInput,
} from 'react-admin';
import { USER_TYPE } from 'src/utils/constants';

/**
 * Excerpt from @viddl/viddl-models
 */
export enum UserType {
	SENDER = 0,
	COURIER = 1,
}

export type UserRecord = {
	id: string;
	email: string;
	name: string;
	user_type: UserType;
	company_name: string;
};

export const courierDefaults = {
	label: 'Futár',
	sort: { field: 'name' },
	reference: 'couriers',
	filter: {
		user_type: USER_TYPE.COURIER,
		is_active: true,
	},
	perPage: 0,
};

const senderDefaults = {
	label: 'Küldő',
	sort: { field: 'company_name' },
	reference: 'senders',
	filter: {
		user_type: USER_TYPE.SENDER,
	},
	perPage: 0,
};

const getDefaults = (type: '_courier' | '_sender') => {
	if (type === '_courier') return courierDefaults;
	if (type === '_sender') return senderDefaults;
	return courierDefaults;
};

type UserInputProps = {
	validate?: any;
	source: '_courier';
	'with-email'?: string;
	fullWidth?: boolean;
	multiselect?: boolean;
	alwaysOn?: boolean;
};

export default function UserInput(props: UserInputProps) {
	const classes = useStyles();

	if (props.multiselect) {
		return (
			<ReferenceArrayInput
				{...getDefaults(props.source)}
				{...props}
				multiselect="true"
			>
				<SelectArrayInput
					style={{ minWidth: 150 }}
					// TODO: someone should look into this
					// @ts-ignore
					classes={classes}
					fullWidth={props.fullWidth}
					optionText={
						<FunctionField<UserRecord>
							render={(record) =>
								record?.user_type === USER_TYPE.SENDER
									? `${record.company_name}`
									: `${record?.name}`
							}
						/>
					}
				/>
			</ReferenceArrayInput>
		);
	}

	return (
		<ReferenceInput {...getDefaults(props.source)} {...props}>
			<SelectInput
				fullWidth={props.fullWidth}
				// TODO: someone should look into this
				// @ts-ignore
				classes={classes}
				optionText={
					props['with-email'] ? (
						<FunctionField<UserRecord>
							render={(record) =>
								`${record?.name} (${record?.email})`
							}
						/>
					) : (
						'name'
					)
				}
			/>
		</ReferenceInput>
	);
}

const useStyles = makeStyles(() => ({
	chip: {
		display: 'contents',
	},
}));
