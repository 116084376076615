import { useEffect, useState } from 'react';
import { refetchLimitInMilliseconds } from '../../../../utils/constants';
import { useDashboardStore } from '../../zustand/store';

const millisecondsProgressRate = (100 * 1000) / refetchLimitInMilliseconds;

export default function useDashboardFetching(props) {
	const { fetchDailyDashboard } = useDashboardStore();

	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const fetchTimer = setInterval(() => {
			fetchDailyDashboard();
			setProgress(0);
		}, refetchLimitInMilliseconds);

		const progressTimer = setInterval(
			() =>
				setProgress(
					(prevState) => prevState + millisecondsProgressRate
				),
			1000
		);

		return () => {
			clearInterval(fetchTimer);
			clearInterval(progressTimer);
		};
	}, [fetchDailyDashboard]);

	return { progress };
}
