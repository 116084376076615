import { Box } from '@material-ui/core';
import { useState } from 'react';
import CourierSearchFilter from 'src/features/courier-search/CourierSearchFilter';
import DepoCourierTable from 'src/features/courier-search/DepoCourierTable';
import { DEFAULT_AVAILABILITY_DURATION } from 'src/features/parcel-search/util/constants';
import { depos } from 'src/utils/constants';

export default function CourierSearch() {
	const [filters, setFilters] = useState({
		availabilityDuration: DEFAULT_AVAILABILITY_DURATION,
	});

	const handleFilterChange = (values: any) => {
		setFilters(values);
	};

	return (
		<>
			<CourierSearchFilter onFilterChange={handleFilterChange} />
			{Object.values(depos).map((depoName) => (
				<Box mb={4}>
					<DepoCourierTable
						filters={filters}
						depo={depoName}
						key={depoName}
					/>
				</Box>
			))}
		</>
	);
}
