import { Redirect, Route } from 'react-router-dom';
import CourierSearch from 'src/pages/courier-search/CourierSearch';
import ParcelSearch from 'src/pages/parcel-search/ParcelSearch';
import DashboardAllDepo from './../pages/dashboard/DashboardAllDepo';

const RedirectHomeToDashboard = () => <Redirect to="/dashboard" />;

const CustomRoutes = [
	<Route exact path="/dashboard" component={DashboardAllDepo} />,
	<Route exact path="/" component={RedirectHomeToDashboard} />,
	<Route exact path="/parcel-search" component={ParcelSearch} />,
	<Route exact path="/courier-search" component={CourierSearch} />,
];

export default CustomRoutes;
