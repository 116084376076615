import {
	LinearProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import moment from 'moment';
import { useQuery } from 'ra-core';
import { parcelStatusList } from '../../utils/constants';

export type ParentChildParcelFieldProps = {
	parcel: any;
	depo: string;
};

export const ParentChildParcelField = ({
	parcel,
	depo,
}: ParentChildParcelFieldProps) => {
	const {
		data: parcelHistory,
		loading,
		error,
	} = useQuery({
		type: 'getParcelHistory',
		resource: 'parcel-search',
		payload: { id: parcel._id, depo },
	});

	const renderParcelDates = (
		availability: any,
		pickup: any,
		delivery: any
	) => {
		return `${
			availability ? moment(availability).format('YYYY-MM-DD') : '-'
		} / ${pickup ? moment(pickup).format('YYYY-MM-DD') : '-'} / ${
			delivery ? moment(delivery).format('YYYY-MM-DD') : '-'
		}`;
	};

	const renderElements = (record: any) => {
		const {
			status,
			_id,
			closed,
			delivery_date,
			pickup_up_date,
			availability,
		} = record;

		const background = { backgroundColor: '#c2c7e7' };

		return (
			<TableRow style={_id === parcel._id ? background : {}} key={_id}>
				<TableCell>{_id}</TableCell>
				<TableCell align="center">
					{
						parcelStatusList.find(
							(statusItem) => statusItem.id === status.code
						)?.name
					}
				</TableCell>
				<TableCell align="center">
					<i className="material-icons">
						{closed ? <Check /> : <Close />}
					</i>
				</TableCell>
				<TableCell align="center">
					{`${status.exception || ''} / ${status.note || ''}`}
				</TableCell>

				<TableCell align="right">
					{renderParcelDates(
						availability,
						pickup_up_date,
						delivery_date
					)}
				</TableCell>
			</TableRow>
		);
	};

	const renderHistory = () => {
		return <TableBody>{parcelHistory.map(renderElements)}</TableBody>;
	};

	if (loading) {
		return <LinearProgress />;
	}

	if (error) {
		return (
			<Typography variant="body1">
				A csomagtörténet nem elérhető
			</Typography>
		);
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell align="center">Belső azonosító</TableCell>
					<TableCell align="center">Státusz</TableCell>
					<TableCell align="center">Lezárt</TableCell>
					<TableCell align="center">
						Kivételkód / Megjegyzés
					</TableCell>
					<TableCell align="right">
						Létrehozás / Felvétel / Leadás
					</TableCell>
				</TableRow>
			</TableHead>
			{renderHistory()}
		</Table>
	);
};
