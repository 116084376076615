import { Box } from '@material-ui/core';
import { useState } from 'react';
import DepoParcelTable from 'src/features/parcel-search/DepoParcelTable';
import ParcelSearchFilter from 'src/features/parcel-search/ParcelSearchFilter';
import { DEFAULT_AVAILABILITY_DURATION } from 'src/features/parcel-search/util/constants';
import { depos } from 'src/utils/constants';

export default function ParcelSearch() {
	const [filters, setFilters] = useState({
		availabilityDuration: DEFAULT_AVAILABILITY_DURATION,
	});

	const handleFilterChange = (values: any) => {
		setFilters(values);
	};

	return (
		<>
			<ParcelSearchFilter onFilterChange={handleFilterChange} />
			{Object.values(depos).map((depoName) => (
				<Box mb={4}>
					<DepoParcelTable
						filters={filters}
						depo={depoName}
						key={depoName}
					/>
				</Box>
			))}
		</>
	);
}
