import { OperationReportSingleDateField } from '../form-fields';
import PostaGlobalPaymentsDateField from '../form-fields/PostaGlobalPaymentsDateField';
import OperationReportFormWrapper from './OperationReportFormWrapper';

const PostaCardPaymentByDateReportForm = (props) => {
	return (
		<OperationReportFormWrapper {...props}>
			<OperationReportSingleDateField />
			<PostaGlobalPaymentsDateField />
		</OperationReportFormWrapper>
	);
};

export default PostaCardPaymentByDateReportForm;
